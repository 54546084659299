/**
 * BRANCHES ACTIONS
 * These actions are for BRANCHES
*/
export const SET_BRANCH_ERROR = 'SET_BRANCH_ERROR';

export const ADD_BRANCH_BEGIN = 'ADD_BRANCH_BEGIN';
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS';

export const ACTIVATE_BRANCH_BEGIN = 'ACTIVATE_BRANCH_BEGIN';
export const ACTIVATE_BRANCH_SUCCESS = 'ACTIVATE_BRANCH_SUCCESS';

export const DEACTIVATE_BRANCH_BEGIN = 'DEACTIVATE_BRANCH_BEGIN';
export const DEACTIVATE_BRANCH_SUCCESS = 'DEACTIVATE_BRANCH_SUCCESS';

export const EDIT_BRANCH_BEGIN = 'EDIT_BRANCH_BEGIN';
export const EDIT_BRANCH_SUCCESS = 'EDIT_BRANCH_SUCCESS';

export const GET_BRANCH_BEGIN = 'GET_BRANCH_BEGIN';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';

export const GET_BRANCHES_BEGIN = 'GET_BRANCHES_BEGIN';
export const GET_BRANCHES_SUCCESS = 'GET_BRANCHES_SUCCESS';

export const GET_MORE_BRANCHES_BEGIN = 'GET_MORE_BRANCHES_BEGIN';
export const GET_MORE_BRANCHES_SUCCESS = 'GET_MORE_BRANCHES_SUCCESS';

export const DELETE_BRANCH_BEGIN = 'DELETE_BRANCH_BEGIN';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';



/** LOAN ACTIONS */

export const SET_LOAN_ERROR = 'SET_LOAN_ERROR';

export const GET_LOAN_BEGIN = 'GET_LOAN_BEGIN';
export const GET_LOAN_SUCCESS = 'GET_LOAN_SUCCESS';

export const GET_LOANS_BEGIN = 'GET_LOANS_BEGIN';
export const GET_LOANS_SUCCESS = 'GET_LOANS_SUCCESS';

export const GET_MORE_LOANS_BEGIN = 'GET_MORE_LOANS_BEGIN';
export const GET_MORE_LOANS_SUCCESS = 'GET_MORE_LOANS_SUCCESS';


/** INSURANCE ACTIONS */

export const SET_INSURANCE_ERROR = 'SET_INSURANCE_ERROR';

export const GET_INSURANCE_BEGIN = 'GET_INSURANCE_BEGIN';
export const GET_INSURANCE_SUCCESS = 'GET_INSURANCE_SUCCESS';

export const GET_INSURANCES_BEGIN = 'GET_INSURANCES_BEGIN';
export const GET_INSURANCES_SUCCESS = 'GET_INSURANCES_SUCCESS';

export const GET_MORE_INSURANCES_BEGIN = 'GET_MORE_INSURANCES_BEGIN';
export const GET_MORE_INSURANCES_SUCCESS = 'GET_MORE_INSURANCES_SUCCESS';

