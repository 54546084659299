/* eslint-disable default-case */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVideo, deleteVideo, editVideo, getVideo, getVideos, SuccessfulAction } from "../middleware/action-creators/coursesActionCreators";
import { CustomTable, GlobalLoader } from "./commonEmbeds";
import { APICALLLIMIT, ASSETS_BASE_URL, breakUpFile, fileUpload, filterVimeoLink, numberWithCommas } from "../middleware/APIs/commonApis";
import { useParams } from "react-router-dom";

let newVideo = {
    name: { en: "", sw: "" },
    photo: "",
    video: { en: "", sw: "" },
    what_you_will_learn: [],
    description: { en: "", sw: "" },
    course_id: "",
    new_length: { minutes: 0, seconds: 0 },
    course_section_id: "",
    on_sale: false,
    price: 0, sale_price: 0, free_video: false,
    discount_ends: "",
    status: "",
    length: 0
};

const VideosComponent = () => {
    const { coursesReducer: {
        videos, lastVideoCount, videosWaitingForResponse, videosResponseMessage, videoAdded,
        videosAddResponseMessage, videosResponseSuccess, videosSmallLoader, pullMoreVideosLoader, currentVideo
    },
        usersReducer: { loggedInUser },
    } = useSelector((state) => state);

    const [reload, setReloading] = React.useState(false);

    const dispatch = useDispatch();
    const urlParams = useParams();

    React.useEffect(() => {
        gettingVideos();
    }, []);

    React.useEffect(() => {

        if (videoAdded) {
            document.getElementById('videoForm').reset();
            newVideo = {
                name: { en: "", sw: "" },
                photo: "",
                video: { en: "", sw: "" },
                what_you_will_learn: [],
                description: { en: "", sw: "" },
                new_length: { minutes: 0, seconds: 0 },
                course_id: "",
                course_section_id: "",
                on_sale: false,
                price: 0, sale_price: 0, free_video: false,
                discount_ends: "",
                status: "", length: 0
            };
            setReloading(!reload);
        }
    }, [videoAdded]);


    React.useEffect(() => {
        if (currentVideo !== null) {
            newVideo = currentVideo;
            setReloading(!reload);
        }
    }, [currentVideo])

    const THeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Rate</th>
            <th scope="col">Edit</th>
            <th scope="col text-danger">Delete</th>
        </tr>
    </thead>

    const TBody = <tbody>
        {
            videos && videos.map((item, i) => (
                <tr className="cursor" key={i}>
                    <th scope="row">{i + 1}.</th>
                    <td className="col-3">{item.name.en}</td>
                    <td>{numberWithCommas(item.price)}</td>
                    <td>{item.rate}</td>
                    {/* <td className={!item.featured ? "text-secondary" : "text-success"}>{item.featured ? "TRUE" : "FALSE"}</td>
                    <td className={!item.best_seller ? "text-secondary" : "text-success"}>{item.best_seller ? "TRUE" : "FALSE"}</td>
                    <td>{item.rate}</td>
                    <td className="link-primary"><Link to={"/section/" + item.ID}>{"Sections"}</Link></td> */}
                    <td className=""><i onClick={() => gettingAVideo(item.ID)} title="Edit" className="fa-solid fa-pen-to-square text-warning"></i></td>
                    <td className="">
                        {/* <i onClick={() => deleteAVideo(i)} className="fa-solid fa-pen-to-square text-warning"></i> */}
                        <div title={"Delete " + item.name.en} className="text-danger cursor"
                            onClick={() => deleteAVideo(i)}
                        >
                            <i className="fa-solid fa-trash-can"></i>
                            <span className="ms-1"> <u>Delete</u></span>
                        </div>
                    </td>
                </tr>
            ))
        }
    </tbody>

    function gettingVideos(appending = false) {
        let params = { start: 0, count: 1000, courseID: urlParams.courseID };

        if (appending) {
            params["start"] = videos.length;
        }

        dispatch(getVideos(params, appending));
    }

    function gettingAVideo(videoID) {

        dispatch(getVideo({ videoID, courseID: urlParams.courseID }));
    }

    function createVideo(e) {
        e.preventDefault();

        let payload = newVideo;

        if (payload.on_sale) {
            payload["discount_ends"] = newVideo.tarehe + " " + newVideo.muda;
            payload["sale_price"] = Number(newVideo.sale_price);
        }

        payload["course_id"] = urlParams.courseID;
        payload["added_by"] = loggedInUser.ID;
        payload["free_video"] = newVideo.price === 0; // This assigns true to payload["freeVideo"] if newVideo.price is 0, and false otherwise.

        dispatch(addVideo(payload));
    };

    function edittingVideo() {

        let payload = newVideo;

        payload["updated_by"] = loggedInUser.ID;
        payload["free_video"] = newVideo.price === 0;

        if (newVideo.muda !== undefined && newVideo.muda !== "") {
            payload["discount_ends"] = newVideo.tarehe + " " + newVideo.muda;
        }

        dispatch(editVideo(payload));
    };

    function inputChange(key, value, i) {

        switch (key) {
            case "sale_price":
                newVideo[key] = Number(value);
                break;
            case "on_sale":
            case "tarehe":
            case "muda":
                newVideo[key] = value;
                break;
            case "price":
                newVideo[key] = Number(value);
                break;
            case "minutes":
            case "seconds":
                newVideo.new_length[key] = Number(value);
                break;
            case "name":
            case "description":
                newVideo[key]["en"] = value;
                break;
            case "video_en":

                newVideo["video"]["en"] = value;
                break;
            case "video_sw":

                newVideo["video"]["sw"] = value;
                break;
            case "what_you_will_learn":
                newVideo[key][i].en = value;
                break;

        }

        setReloading(!reload);

    };

    function addNewRow(key) {
        newVideo[key].push({ en: "", sw: "" });
        setReloading(!reload);
    }

    const deleteAVideo = (index) => {
        if (window.confirm("Are you sure you want to delete this Video?")) {
            let tmpCats = videos.slice();
            tmpCats[index].updated_by = loggedInUser.ID
            dispatch(deleteVideo(tmpCats[index]));
        }
    };

    function sendingFile(file) {
        newVideo["photo"] = file;

        setReloading(!reload);
    };

    function clearForm() {
        dispatch(SuccessfulAction("GET_VIDEO_SUCCESS", null, null));
        newVideo = {
            name: { en: "", sw: "" },
            photo: "",
            video: { en: "", sw: "" },
            what_you_will_learn: [],
            description: { en: "", sw: "" },
            new_length: { minutes: 0, seconds: 0 },
            course_id: "",
            course_section_id: "",
            on_sale: false,
            price: 0, sale_price: 0,
            discount_ends: "",
            status: "", length: 0
        };
        document.getElementById('videoForm').reset();
    };

    function deleteThisRow(index, key) {
        newVideo[key].splice(index, 1);
        setReloading(!reload);
    }

    return (
        <div className="text-start mt-3">

            <span className="h5"> <u>{"Videos"}</u></span>
            <div className="rowBtn align-items-start">
                {
                    videosWaitingForResponse ? <GlobalLoader /> :
                        videos.length === 0 ? <span className="h6 text-danger">{videosResponseMessage}</span> :
                            <div className="col-lg-6 col-md-5 col-sm-12" style={{ paddingTop: 10 }}>
                                <CustomTable tableHeader={THeader} tableBody={TBody} />

                                {lastVideoCount === APICALLLIMIT && (
                                    <div className="text-center">
                                        {pullMoreVideosLoader ? <GlobalLoader /> :
                                            <span onClick={() => gettingVideos(true)} className="loadMore">Load more</span>}
                                    </div>
                                )}
                            </div>
                }


                {
                    // videosSmallLoader ? <GlobalLoader /> :
                    currentVideo === null ?
                        <div className="col-lg-5 col-md-5 col-sm-12 whiteElevation">
                            <div className="h5">Add a new Video</div>
                            <form className="form" onSubmit={createVideo} id="videoForm">

                                <input type="text" className="form-control mb-2" placeholder="Video name" name="names" onChange={(e) => inputChange("name", e.target.value)} required />
                                <textarea type="text" className="form-control  mb-2" placeholder="Description" name="descriptions" onChange={(e) => inputChange("description", e.target.value)} required />


                                <div className="my-2">
                                    <div className="" style={{ fontSize: 14, color: "#000000" }}>What you will learn</div>
                                    {
                                        newVideo.what_you_will_learn.map((req, i) => (
                                            <div className="d-flex flex-row align-items-center" key={i}>
                                                <span className="me-1">{i + 1}.</span>
                                                <input type="text" className="form-control  mb-2" placeholder="" name="what_you_will_learn" onChange={(e) => inputChange("what_you_will_learn", e.target.value, i)} required />
                                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "what_you_will_learn")} />
                                            </div>
                                        ))
                                    }
                                    <span onClick={() => addNewRow("what_you_will_learn")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                                </div>

                                <div>
                                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select an image (landscape)</div>
                                    <div className="logoImageBg">
                                        <img src={newVideo.photo === "" ? "assets/img/placeholder.png" : (ASSETS_BASE_URL + newVideo.photo)} alt="Logo" className="img-fluid" style={{ width: 300, height: 200 }} />
                                    </div>
                                    <input type="file" onChange={(e) => breakUpFile(e, sendingFile)} className="my-2" id="img" accept="image/*" />
                                </div>


                                <div className="rowBtn">
                                    <div>
                                        <div className="" style={{ fontSize: 14, color: "#000000" }}>English video link</div>
                                        <input type="text" onChange={(e) => inputChange("video_en", e.target.value)} className="my-2" />
                                    </div>
                                    <div>
                                        <div className="" style={{ fontSize: 14, color: "#000000" }}>Swahili video link</div>
                                        <input type="text" onChange={(e) => inputChange("video_sw", e.target.value)} className="my-2" />
                                    </div>
                                </div>

                                <div className="" style={{ fontSize: 14, color: "#000000" }}>Video length</div>
                                <div className="d-flex flex-row">
                                    <input type="number" onChange={(e) => inputChange("minutes", e.target.value)} className="my-2" placeholder="Minutes" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="number" onChange={(e) => inputChange("seconds", e.target.value)} className="my-2" placeholder="Seconds" />
                                </div>

                                <div className="col-md-6 d-flex align-items-center mb-2">
                                    <label className="me-2">On sale?</label>
                                    <input type="checkbox" className="" checked={newVideo.on_sale} name="on_sale" onChange={() => inputChange("on_sale", !newVideo.on_sale)} />
                                </div>

                                <div className="rowBtn">
                                    <input type="number" className="form-control mb-2" placeholder="Price" name="price" onChange={(e) => inputChange("price", e.target.value)} required />
                                    <div className="col-1"></div>
                                    {newVideo.on_sale && (
                                        <input type="number" className="form-control mb-2" placeholder="Sale price" name="sale_price"
                                            onChange={(e) => inputChange("sale_price", e.target.value)} required />)}
                                </div>



                                {
                                    newVideo.on_sale && (
                                        <div className="rowBtn">
                                            <div className="col-3">
                                                <span>Sale end date</span>
                                                <input type="date" className="col-10 mb-2" name="date" onChange={(e) => inputChange("tarehe", e.target.value)} required />
                                            </div>

                                            <div className="col-1"></div>
                                            <div className="col-2">
                                                <span>Sale end time</span>
                                                <input type="time" className="col-12 mb-2 " name="muda" onChange={(e) => inputChange("muda", e.target.value)} required />
                                            </div>
                                        </div>
                                    )
                                }

                                <button type="submit" className="editBtn col-3 cursor btn text-white" style={{ height: 40 }}>{videosSmallLoader ? <GlobalLoader color="text-white" /> : "Submit"}</button>
                                <div className={videosResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{videosAddResponseMessage}</div>
                            </form>
                        </div>
                        :
                        <div className="col-lg-5 col-md-6 col-sm-12 whiteElevation">
                            <div className="rowBtn">
                                <div className="h5">Edit a Video</div>
                                <i title="Close" className="fa-solid fa-circle-xmark fa-xl text-danger cursor"
                                    onClick={clearForm}></i>
                            </div>
                            <div className="form">
                                <>
                                    <label>Viedo name</label>
                                    <input type="text" className="form-control mb-2" value={newVideo.name.en} onChange={(e) => inputChange("name", e.target.value)} required />
                                </>

                                <>
                                    <label>Viedo description</label>
                                    <textarea type="text" className="form-control  mb-2" value={newVideo.description.en} onChange={(e) => inputChange("description", e.target.value)} required />
                                </>

                                <div className="my-2">
                                    <div className="" style={{ fontSize: 14, color: "#000000" }}>What you will learn</div>
                                    {
                                        newVideo.what_you_will_learn.map((req, i) => (
                                            <div className="d-flex flex-row align-items-center" key={i}>
                                                <span className="me-1">{i + 1}.</span>
                                                <input type="text" className="form-control  mb-2" value={req.en} onChange={(e) => inputChange("what_you_will_learn", e.target.value, i)} required />
                                                <i className="fa fa-close fa-xl text-danger ms-1" title="Delete" onClick={() => deleteThisRow(i, "what_you_will_learn")} />
                                            </div>
                                        ))
                                    }
                                    <span onClick={() => addNewRow("what_you_will_learn")} className="col-3 editBtn mb-2 mt-0">Add New</span>
                                </div>

                                <div>
                                    <div className="" style={{ fontSize: 14, color: "#000000" }}>Select an image (landscape)</div>
                                    <div className="logoImageBg">
                                        <img src={newVideo.photo === "" ? "assets/img/placeholder.png" : ASSETS_BASE_URL + newVideo.photo} alt="Logo" className="img-fluid" style={{ width: 300, height: 200 }} />
                                    </div>
                                    <input type="file" onChange={(e) => breakUpFile(e, sendingFile)} className="my-2" id="img" accept="image/*" />
                                </div>


                                <div className="rowBtn">
                                    <div>
                                        <div className="" style={{ fontSize: 14, color: "#000000" }}>English video link</div>
                                        <input type="text" value={newVideo.video.en} onChange={(e) => inputChange("video_en", e.target.value)} className="my-2" />
                                    </div>
                                    <div>
                                        <div className="" style={{ fontSize: 14, color: "#000000" }}>Swahili video link</div>
                                        <input type="text" value={newVideo.video.sw} onChange={(e) => inputChange("video_sw", e.target.value)} className="my-2" />
                                    </div>
                                </div>


                                <p className="" style={{ fontSize: 14, color: "#000000" }}>Video length</p>
                                <div className="d-flex flex-row">

                                    <input type="number" value={newVideo.new_length.minutes} onChange={(e) => inputChange("minutes", e.target.value)} className="my-2" />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="number" value={newVideo.new_length.seconds} onChange={(e) => inputChange("seconds", e.target.value)} className="my-2" />
                                </div>


                                <div className="col-md-6 d-flex align-items-center mb-2">
                                    <label className="me-2">On sale?</label>
                                    <input type="checkbox" className="" checked={newVideo.on_sale} name="on_sale" onChange={() => inputChange("on_sale", !newVideo.on_sale)} />
                                </div>

                                <div className="rowBtn">
                                    <input type="number" className="form-control mb-2" value={newVideo.price} onChange={(e) => inputChange("price", e.target.value)} required />
                                    <div className="col-1"></div>
                                    {newVideo.on_sale && (
                                        <input type="number" className="form-control mb-2" value={newVideo.sale_price}
                                            onChange={(e) => inputChange("sale_price", e.target.value)} required />)}
                                </div>



                                {
                                    newVideo.on_sale && (
                                        <div className="rowBtn">
                                            <div className="col-3">
                                                <span>Sale end date</span>
                                                <input type="date" className="col-10 mb-2" onChange={(e) => inputChange("tarehe", e.target.value)} required />
                                            </div>

                                            <div className="col-1"></div>
                                            <div className="col-2">
                                                <span>Sale end time</span>
                                                <input type="time" className="col-12 mb-2 " onChange={(e) => inputChange("muda", e.target.value)} required />
                                            </div>
                                        </div>
                                    )
                                }

                                <button onClick={edittingVideo} className="col-3 editBtn cursor btn text-white" style={{ height: 40 }}>{videosSmallLoader ? <i className={"fa-solid fa-circle-notch fa-spin fa-2x text-white"}></i> : "Edit"}</button>
                                <div className={videosResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{videosResponseMessage}</div>

                            </div>
                        </div>
                }
            </div>

            <br />
            <br />
            <br />

        </div>
    )
}

export default VideosComponent;