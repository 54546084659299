/**
 * COURSES ACTIONS
 * These actions are for course section
*/
export const SET_COURSE_ERROR = 'SET_COURSE_ERROR'

export const ADD_COURSE_BEGIN = 'ADD_COURSE_BEGIN';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';

export const DELETE_COURSE_BEGIN = 'DELETE_COURSE_BEGIN';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';

export const EDIT_COURSE_BEGIN = 'EDIT_COURSE_BEGIN';
export const EDIT_COURSE_SUCCESS = 'EDIT_COURSE_SUCCESS';

export const GET_COURSE_BEGIN = 'GET_COURSE_BEGIN';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';

export const GET_COURSES_BEGIN = 'GET_COURSES_BEGIN';
export const GET_COURSES_SUCCESS = 'GET_COURSES_SUCCESS';

export const GET_MORE_COURSES_BEGIN = 'GET_MORE_COURSES_BEGIN';
export const GET_MORE_COURSES_SUCCESS = 'GET_MORE_COURSES_SUCCESS';


/**
 * COURSE SECTION ACTIONS
 * These actions are for course section
*/
export const SET_COURSE_SECTION_ERROR = 'SET_COURSE_SECTION_ERROR'

export const ADD_COURSE_SECTION_BEGIN = 'ADD_COURSE_SECTION_BEGIN';
export const ADD_COURSE_SECTION_SUCCESS = 'ADD_COURSE_SECTION_SUCCESS';

export const DELETE_COURSE_SECTION_BEGIN = 'DELETE_COURSE_SECTION_BEGIN';
export const DELETE_COURSE_SECTION_SUCCESS = 'DELETE_COURSE_SECTION_SUCCESS';

export const EDIT_COURSE_SECTION_BEGIN = 'EDIT_COURSE_SECTION_BEGIN';
export const EDIT_COURSE_SECTION_SUCCESS = 'EDIT_COURSE_SECTION_SUCCESS';

export const GET_COURSE_SECTION_BEGIN = 'GET_COURSE_SECTION_BEGIN';
export const GET_COURSE_SECTION_SUCCESS = 'GET_COURSE_SECTION_SUCCESS';

export const GET_COURSE_SECTIONS_BEGIN = 'GET_COURSE_SECTIONS_BEGIN';
export const GET_COURSE_SECTIONS_SUCCESS = 'GET_COURSE_SECTIONS_SUCCESS';

export const GET_MORE_COURSE_SECTIONS_BEGIN = 'GET_MORE_COURSE_SECTIONS_BEGIN';
export const GET_MORE_COURSE_SECTIONS_SUCCESS = 'GET_MORE_COURSE_SECTIONS_SUCCESS';


/**
 * VIDEO SECTION ACTIONS
 * These actions are for course section
*/
export const SET_VIDEO_ERROR = 'SET_VIDEO_ERROR'

export const ADD_VIDEO_BEGIN = 'ADD_VIDEO_BEGIN';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';

export const DELETE_VIDEO_BEGIN = 'DELETE_VIDEO_BEGIN';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';

export const EDIT_VIDEO_BEGIN = 'EDIT_VIDEO_BEGIN';
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS';

export const GET_VIDEO_BEGIN = 'GET_VIDEO_BEGIN';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';

export const GET_VIDEOS_BEGIN = 'GET_VIDEOS_BEGIN';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';

export const GET_MORE_VIDEOS_BEGIN = 'GET_MORE_VIDEOS_BEGIN';
export const GET_MORE_VIDEOS_SUCCESS = 'GET_MORE_VIDEOS_SUCCESS';


