import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSkill, deleteSkill, editSkill, getSkills, setSkillsError } from "../middleware/action-creators/categoriesActionCreators";
import { CoursesHeader, CustomTable, GlobalLoader } from "./commonEmbeds";
import { APICALLLIMIT } from "../middleware/APIs/commonApis";


const SkillsComponent = ({ menu }) => {
    const { categoriesReducer: {
        skills, lastSkillCount, skillsWaitingForResponse, skillsResponseMessage, skillsResponseSuccess, skillsSmallLoader, skillPullMoreLoader
    },
        usersReducer: { loggedInUser },
    } = useSelector((state) => state);

    const [activeSkill, setActiveSkill] = React.useState(null);

    const dispatch = useDispatch();

    React.useEffect(() => {

        gettingSkills();

    }, []);

    const THeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Edit</th>
            <th scope="col text-danger">Delete</th>
        </tr>
    </thead>

    const TBody = <tbody>
        {
            skills && skills.map((item, i) => (
                <tr className="cursor">
                    <th scope="row">{i + 1}.</th>
                    <td>{item.name.en}</td>
                    <td className=""><i onClick={() => {
                        dispatch(setSkillsError(null, null))
                        setActiveSkill(item);
                    }} title="Edit" className="fa-solid fa-pen-to-square text-warning"></i></td>
                    <td className="">
                        <div title={"Delete " + item.name.en} className="text-danger cursor"
                            onClick={() => deleteASkill(i)}
                        >
                            <i className="fa-solid fa-trash-can"></i>
                            <span className="ms-1"> <u>Delete</u></span>
                        </div>
                    </td>
                </tr>
            ))
        }
    </tbody>

    function gettingSkills(appending = false) {
        let params = { start: 0, count: APICALLLIMIT };

        if (appending) {
            params["start"] = skills.length;
        }

        dispatch(getSkills(params, appending));
    }

    function createSkill(e) {
        e.preventDefault();

        let form = {},

            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        };

        form["added_by"] = loggedInUser.ID
        form["name"] = { en: form.names }

        dispatch(addSkill(form));
    };

    function edittingSkill() {

        let payload = activeSkill;

        payload["updated_by"] = loggedInUser.ID

        dispatch(editSkill(payload));
    };

    function inputChange(key, value) {

        setActiveSkill(prevState => ({
            ...prevState,
            name: {
                ...prevState.name, [key]: value
            }

        }));

    };

    const deleteASkill = (index) => {
        if (window.confirm("Are you sure you want to delete this Skill?")) {
            let tmpCats = skills.slice();
            tmpCats[index].updated_by = loggedInUser.ID
            dispatch(deleteSkill(tmpCats[index]));
        }
    }


    return (
        <div className="text-start mt-3">

            <CoursesHeader menu={menu} />

            <div className="rowBtn align-items-start">
                {
                    skillsWaitingForResponse ? <GlobalLoader /> :
                        skills.length === 0 ? <span className="h6 text-danger">{skillsResponseMessage}</span> :
                            <div className="col-lg-4 col-md-5 col-sm-12" style={{ paddingTop: 10 }}>
                                <CustomTable tableHeader={THeader} tableBody={TBody} />

                                {lastSkillCount === APICALLLIMIT && (
                                    <div className="text-center">
                                        {skillPullMoreLoader ? <GlobalLoader /> :
                                            <span onClick={() => gettingSkills(true)} className="loadMore">Load more</span>}
                                    </div>
                                )}
                            </div>
                }


                {
                    activeSkill === null ?
                        <div className="col-lg-4 col-md-5 col-sm-12 whiteElevation">
                            <div className="h5">Add new Skill</div>
                            <form className="form" onSubmit={createSkill}>
                                <div className="row justify-content-between align-items-end">
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label className="mb-1">Skill Name</label>
                                        <input type="text" className="form-control" name="names" required />
                                    </div>

                                    <button type="submit" className="editBtn col-2 cursor btn text-white" style={{ height: 40 }}>{skillsSmallLoader ? <GlobalLoader color="text-white" /> : "Submit"}</button>
                                </div>

                                <div className={skillsResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{skillsResponseMessage}</div>
                            </form>
                        </div>
                        :
                        <div className="col-lg-4 col-md-5 col-sm-12 whiteElevation">
                            <div className="rowBtn">
                                <div className="h5">Edit a Skill</div>
                                <i title="Close" className="fa-solid fa-circle-xmark fa-xl text-danger cursor"
                                    onClick={() => setActiveSkill(null)}></i>
                            </div>
                            <div className="form">
                                <div className="row justify-content-between align-items-end">
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label className="mb-1">Skill Name</label>
                                        <input type="text" className="form-control" value={activeSkill.name.en} onChange={(e) => inputChange("en", e.target.value)} />
                                    </div>

                                    <button onClick={edittingSkill} className="editBtn col-2 cursor btn text-white" style={{ height: 40 }}>{skillsSmallLoader ? <GlobalLoader color="text-white" /> : "Submit"}</button>
                                </div>
                                {/* <div title="Delete Skill" className="float-end text-danger cursor mt-2">
                                    <i className="fa-solid fa-trash-can"></i>
                                    <span className="ms-1"> <u>Delete</u></span>
                                </div> */}
                                <div className={skillsResponseSuccess ? "text-success" : "text-danger" + " text-left mt-2"}>{skillsResponseMessage}</div>

                            </div>
                        </div>
                }
            </div>

        </div>
    )
}

export default SkillsComponent;