/*
  Ranks
  These actions are for Ranks/Priviledges
*/
export const SET_RANKS_ERROR = 'SET_RANKS_ERROR'

export const SET_SELECTED_RANK = 'SET_SELECTED_RANK'

export const ADD_RANK_BEGIN   = 'ADD_RANK_BEGIN';
export const ADD_RANK_SUCCESS = 'ADD_RANK_SUCCESS';

export const EDIT_RANK_BEGIN   = 'EDIT_RANK_BEGIN';
export const EDIT_RANK_SUCCESS = 'EDIT_RANK_SUCCESS';

export const GET_RANKS_BEGIN   = 'GET_RANKS_BEGIN';
export const GET_RANKS_SUCCESS = 'GET_RANKS_SUCCESS';

export const DELETE_RANK_BEGIN   = 'DELETE_RANK_BEGIN';
export const DELETE_RANK_SUCCESS = 'DELETE_RANK_SUCCESS';
