import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser, editUser, getUsers, manualAllocation } from "../middleware/action-creators/usersActionsCreators";
import { ACCOUNT_TYPES, APICALLLIMIT, numberWithCommas, randomPasswordGenerator } from "../middleware/APIs/commonApis";
import { CoursesHeader, GlobalLoader } from "./commonEmbeds";

const ContentCreators = () => {

    const {
        usersReducer: {
            loggedInUser, usersSmallLoader, userResponseMessage, userResponseSuccess, users, userWaitingForResponse,
            lastUsersPullCount, usersLoader
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [activeUser, setActiveUser] = React.useState(null);


    React.useEffect(() => {
        document.title = "Instructors | Ekozi";
        gettingUsers();
    }, []);


    function gettingUsers(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, accountType: ACCOUNT_TYPES.CONTENT_CREATOR };

        if (appending) {
            params["start"] = users.length;
        }

        dispatch(getUsers(params, appending));

    };

    function inputChange(key, value) {

        setActiveUser(prevState => ({
            ...prevState, [key]: value
        }));

    };


    function editAdmin() {
        dispatch(editUser(activeUser));
    }

    function addingAdmin(e) {
        e.preventDefault();

        let form = {},

            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        };

        const tmpPass = randomPasswordGenerator();
        form["channel"] = "direct";
        form["percentage_split"] = Number(form.percentage_split);
        form["account_type"] = ACCOUNT_TYPES.CONTENT_CREATOR;
        form["password"] = tmpPass;
        form["added_by"] = loggedInUser.ID ?? 1;
        form["confirm_password"] = tmpPass;
        form["account_status"] = "active";

        dispatch(addUser(form));
    };

    function partialSettlement(index) {
        let ref = window.prompt('Enter amount to be allocated to the account belonging to ' + users[index].first_name + " " + users[index].last_name);

        if (ref !== undefined && ref !== null && ref.trim().length !== 0) {

            if ((Number(ref) + Number(users[index].amount_settled)) > Number(users[index].revenue_generated)) {
                return window.alert('Entered amount is greater than the amount generated!');
            } else {
                const payload = {
                    amount_settled: Number(ref),
                    ID: users[index].ID,
                    revenue_generated: 0
                };
                dispatch(manualAllocation(payload));
            }
        };
    }

    return (
        <div className="d-flex flex-row justify-content-between">
            <div className=" col-lg-7 col-md-7 col-sm-7 my-2 p-3 bg-white text-start">
                <CoursesHeader menu={{ name: "Instructors" }} />

                {

                    userWaitingForResponse ? <GlobalLoader /> :

                        users.length === 0 ? <div className={userResponseSuccess ? "text-success" : "text-danger"}>{userResponseMessage}</div> :
                            <table className="table bg-white rounded table-hover border table-responsive">
                                <thead className="text-black">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First</th>
                                        <th scope="col">Last</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Revenue Generated (TZS)</th>
                                        <th scope="col">Amount Settled (TZS)</th>
                                        <th scope="col">% split</th>
                                        <th scope="col">...</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users.map((item, i) => (
                                            <tr onClick={() => setActiveUser(item)} className="cursor" key={i}>
                                                <th scope="row">{numberWithCommas(i + 1)}</th>
                                                <td>{item.first_name}</td>
                                                <td>{item.last_name}</td>
                                                <td>{item.email}</td>
                                                <td>{numberWithCommas(item.revenue_generated)}</td>
                                                <td>{numberWithCommas(item.amount_settled)}</td>
                                                <td className="text-capitalize">{numberWithCommas(item.percentage_split)}</td>
                                                <td>
                                                    {
                                                        item.revenue_generated !== item.amount_settled ?
                                                            <span className={"fa fa-exchange text-warning cursor"} title="Revenue Allocation"
                                                                onClick={() => partialSettlement(i)}>
                                                            </span> : null
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                }

                {lastUsersPullCount === APICALLLIMIT && (
                    <div className="text-center">
                        {usersLoader ? <GlobalLoader /> :
                            <span onClick={() => gettingUsers(true)} className="loadMore">Load more</span>}
                    </div>
                )}
            </div>


            <div className="col-lg-4 col-md-5 col-sm-6 p-2">

                {
                    activeUser !== null ?

                        <div className="p-3 text-start whiteElevation">

                            <div className="h5">Edit Instructor</div>
                            <div className="row">
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={activeUser.first_name} onChange={(e) => inputChange("first_name", e.target.value)} />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" value={activeUser.last_name} onChange={(e) => inputChange("last_name", e.target.value)} />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                    <input type="email" className="form-control" value={activeUser.email} onChange={(e) => inputChange("email", e.target.value)} />
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Percent Split</label>
                                    <input type="number" className="form-control" value={activeUser.percentage_split} onChange={(e) => inputChange("percentage_split", Number(e.target.value))} />
                                </div>
                            </div>
                            {/* <div className=" my-md-2 my-3 pt-2"> <label>Role</label>
                                <select id="sub" onChange={(e) => inputChange("role", e.target.value)} >
                                    <option value="" hidden>{activeUser.role_name}</option>
                                    {
                                        userPrivileges.map((role, i) => (
                                            <option key={i} value={role.ID}>{role.rank}</option>
                                        ))
                                    }
                                </select>
                            </div> */}
                            <div className={userResponseSuccess ? "text-success" : "text-danger"}>{userResponseMessage}</div>
                            <div className="d-flex justify-content-center">
                                {
                                    usersSmallLoader ?
                                        <GlobalLoader />
                                        :
                                        <button onClick={editAdmin} className="btn editBtn mt-3 px-5">Edit</button>
                                }
                            </div>
                        </div>
                        :
                        <div className="p-3 text-start whiteElevation">
                            <div className="h5">Add new Instructor</div>
                            <form className="form" onSubmit={addingAdmin}>
                                <div className="row">
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" name="first_name" required />
                                    </div>
                                    <div className="col-md-6 mt-md-0 mt-3">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" name="last_name" required />
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                        <input type="email" className="form-control" name="email" required />
                                    </div>
                                </div>
                                <div className="row pt-2">
                                    <div className="col-md-6 mt-md-0 mt-3"> <label>Percent Split</label>
                                        <input type="number" className="form-control" name="percentage_split" />
                                    </div>
                                </div>
                                <div className={userResponseSuccess ? "text-success" : "text-danger"}>{userResponseMessage}</div>
                                <div className="d-flex justify-content-center">
                                    {
                                        usersSmallLoader ?
                                            <GlobalLoader />
                                            :
                                            <button type="submit" className="btn editBtn mt-3 px-5">Add</button>
                                    }
                                </div>

                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default ContentCreators;