import React from "react";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import Sections from "../components/sections";

const SectionPage = () => {

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <Sections />

                </section>
            </section>

            <Footer />

        </section>
    )
}

export default SectionPage;