import React from "react";

import { editRank, addRank } from "../middleware/action-creators/PriviledgesActionCreators";
import { useDispatch, useSelector } from "react-redux";


let priviledgeValues = {
    list: false,
    add: false,
    edit: false,
    delete: false,
    extract: false
}

let sections = {
    user_management: { ...priviledgeValues },
    end_user_management: { ...priviledgeValues },
    settings_management: { ...priviledgeValues },
    sales_management: { ...priviledgeValues },
    privileges_management: { ...priviledgeValues },
    courses_management: { ...priviledgeValues },
    statistics_management: { ...priviledgeValues },
}

let localData = {
    privilege_settings: JSON.parse(JSON.stringify(sections))
}

function PriviledgeGroupsForm(props) {

    const [currentState, forceUpdate] = React.useState(1)
    const loggedInUser = useSelector((state) => state.usersReducer.loggedInUser)
    const selectedRank = useSelector((state => state.PriviledgesReducer.selectedRank))
    const rolesResponseSuccess = useSelector((state) => state.PriviledgesReducer.ranksResponseSuccess)
    const rolesLoading = useSelector((state) => state.PriviledgesReducer.ranksWaitingForResponse)
    const ranksResponseMessage = useSelector((state) => state.PriviledgesReducer.ranksResponseMessage)
    const dispatch = useDispatch()


    React.useEffect(() => {

        if (props.action === "add") {
            localData = {
                privilege_settings: JSON.parse(JSON.stringify(sections))
            }
        }
    }, [props.action]);

    const segmentChange = (data, key, value) => {
        localData["privilege_settings"][data][key] = value;
        forceUpdate(currentState + 1);
    }

    const submit = (e) => {
        e.preventDefault()
        localData["updated_by"] = loggedInUser.ID

        if (props.action === "add") {
            localData["created_by"] = loggedInUser.ID;
            dispatch(addRank(localData))
        } else {
            dispatch(editRank(localData))
        }
    }


    if (props.action === "edit" && localData.ID !== selectedRank.ID) {
        Object.assign(localData, selectedRank)
    }

    return (
        <div className="row noMargin">


            <div className="col whiteElevation">

                <div className="row">
                    <div className="col-12 titleZone">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">

                                    <div className="h2">
                                        {props.action === "add" ? "Add role" : "Update role"}
                                    </div>
                                    <span
                                        className="fa fa-times-circle fa-2x cursor ml-auto text-center text-danger"
                                        title={"Close"}
                                        onClick={props.hideForm}
                                    ></span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">

                        <form className="form" onSubmit={submit}>

                            <div className="form-group">
                                <label>{"Role name"}</label><br />
                                <input
                                    className="form-control"
                                    type="text"
                                    name="rank"
                                    value={localData.rank}
                                    onChange={
                                        (e) => {
                                            localData["rank"] = e.target.value
                                            forceUpdate(currentState + 1)
                                        }
                                    }
                                />
                            </div>

                            <PriviledgeSegment
                                segmentName={"Admin management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over other system user accounts"
                                skipKeys={[]}
                                segmentKey="user_management"
                                segmentData={localData.privilege_settings.user_management}
                                segmentChange={segmentChange}
                            />

                            <PriviledgeSegment
                                segmentName={"End user management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over customer accounts"
                                skipKeys={["delete", "add"]}
                                segmentKey="end_user_management"
                                segmentData={localData.privilege_settings.end_user_management}
                                segmentChange={segmentChange}
                            />

                            <PriviledgeSegment
                                segmentName={"Settings management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over settings management"
                                skipKeys={["extract"]}
                                segmentKey="settings_management"
                                segmentData={localData.privilege_settings.settings_management}
                                segmentChange={segmentChange}
                            />


                            <PriviledgeSegment
                                segmentName={"Roles management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over roles management"
                                skipKeys={["extract"]}
                                segmentKey="privileges_management"
                                segmentData={localData.privilege_settings.privileges_management}
                                segmentChange={segmentChange}
                            />

                            <PriviledgeSegment
                                segmentName={"Courses management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over analytics management"
                                skipKeys={[""]}
                                segmentKey="courses_management"
                                segmentData={localData.privilege_settings.courses_management}
                                segmentChange={segmentChange}
                            />

                            <PriviledgeSegment
                                segmentName={"Sales management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over analytics management"
                                skipKeys={["add", "edit", "delete"]}
                                segmentKey="sales_management"
                                segmentData={localData.privilege_settings.sales_management}
                                segmentChange={segmentChange}
                            />

                            <PriviledgeSegment
                                segmentName={"Statistics management"}
                                // segmentExplanation="These are the priviledges system users with this rank setting will have over analytics management"
                                skipKeys={["add", "edit", "delete", "extract"]}
                                segmentKey="statistics_management"
                                segmentData={localData.privilege_settings.statistics_management}
                                segmentChange={segmentChange}
                            />

                            <div style={{ marginBottom: 80 }}>
                                <p className={"text-center " + (rolesResponseSuccess === true ? "text-success" : "text-danger")}>
                                    {ranksResponseMessage}
                                </p>
                                <button className="btn editBtn">{rolesLoading === true ? <span><span className="fa fa-spin fa-spinner"></span> Loading...</span> : "Submit"}</button>
                            </div>


                        </form>


                    </div>


                </div>

            </div>

        </div>

    )
}

function PriviledgeSegment(props) {

    return (
        <div className="form-group">
            <label>{props.segmentName}</label><br />
            <p>
                {
                    props.skipKeys.includes("list") === true ? null :
                        <label className="formCheckBoxesOption">
                            <input
                                type="checkbox"

                                checked={props.segmentData.list}
                                value={props.segmentData.list}
                                onChange={(e) => {
                                    //if list is false, then all other options should be false as well
                                    //as list is the minimum permission required
                                    if (e.target.checked === false) {
                                        props.segmentChange(props.segmentKey, "add", e.target.checked)
                                        props.segmentChange(props.segmentKey, "edit", e.target.checked)
                                        props.segmentChange(props.segmentKey, "delete", e.target.checked)
                                        props.segmentChange(props.segmentKey, "extract", e.target.checked)
                                    }
                                    props.segmentChange(props.segmentKey, "list", e.target.checked)
                                }}
                            /> {"list"} &nbsp;
                        </label>
                }

                {
                    props.skipKeys.includes("add") === true ? null :
                        <label className="formCheckBoxesOption">
                            <input
                                type="checkbox"
                                checked={props.segmentData.add}
                                value={props.segmentData.add}
                                onChange={(e) => {
                                    if (e.target.checked && props.segmentData.list === false) {
                                        props.segmentChange(props.segmentKey, "list", e.target.checked)
                                    }
                                    props.segmentChange(props.segmentKey, "add", e.target.checked)
                                }}
                            /> {"Add"}&nbsp;
                        </label>

                }

                {
                    props.skipKeys.includes("edit") === true ? null :
                        <label className="formCheckBoxesOption">
                            <input
                                type="checkbox"
                                checked={props.segmentData.edit}
                                value={props.segmentData.edit}
                                onChange={(e) => {
                                    if (e.target.checked && props.segmentData.list === false) {
                                        props.segmentChange(props.segmentKey, "list", e.target.checked)
                                    }
                                    props.segmentChange(props.segmentKey, "edit", e.target.checked)
                                }}
                            /> {"Edit"}&nbsp;
                        </label>
                }

                {
                    props.skipKeys.includes("delete") === true ? null :
                        <label className="formCheckBoxesOption">
                            <input
                                type="checkbox"
                                checked={props.segmentData.delete}
                                value={props.segmentData.delete}
                                onChange={(e) => {
                                    if (e.target.checked && props.segmentData.list === false) {
                                        props.segmentChange(props.segmentKey, "list", e.target.checked)
                                    }
                                    props.segmentChange(props.segmentKey, "delete", e.target.checked)
                                }}
                            /> {"Delete"}&nbsp;
                        </label>
                }

                {
                    props.skipKeys.includes("extract") === true ? null :
                        <label className="formCheckBoxesOption">
                            <input
                                type="checkbox"
                                checked={props.segmentData.extract}
                                value={props.segmentData.extract}
                                onChange={(e) => {
                                    if (e.target.checked && props.segmentData.list === false) {
                                        props.segmentChange(props.segmentKey, "list", e.target.checked)
                                    }
                                    props.segmentChange(props.segmentKey, "extract", e.target.checked)
                                }}
                            /> {"Extract"}&nbsp;
                        </label>
                }

            </p>
            <span className="orangeText fieldDescription">{props.segmentExplanation}</span>
            <hr />
        </div>

    )
}

export default PriviledgeGroupsForm;
