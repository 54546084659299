import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { SuccessfulAction, getUserPrivileges } from "../middleware/action-creators/usersActionsCreators";
import { getUserData, networkRequest } from "../middleware/APIs/commonApis";
import Profile from "../pages/profile";
import { Link } from "react-router-dom";

export const Header = () => {

    return (
        <header className="header d-flex align-items-center justify-content-between" style={{ backgroundColor: "#BD7F00" }}>
            <div className="d-flex flex-row">
                <div className="sidebar-toggle-box d-none">
                    <div className="fa fa-bars tooltips text-white" data-placement="right" data-original-title="Toggle Navigation">
                    </div>
                </div>

                <a href="/dashboard" className="logo"><b>EKozi</b></a>

            </div>

            <div className="top-menu">
                <ul className="logout" onClick={() => localStorage.clear()}>
                    <li><a className="logout" href="/">Logout</a></li>
                </ul>
            </div>
        </header>
    )
}

export const Footer = () => {
    return null;
    return (
        <footer className="footer fixed-bottom">
            <div className="text-center">
                &copy;{new Date().getFullYear()} - Ekozi an Online Learning
                {/* <a href="/" className="go-top">
                    <i className="fa fa-angle-up"></i>
                </a> */}
            </div>
        </footer>
    )
};

export const GlobalLoader = ({ color = "primaryColor" }) => {
    return (
        <div className="d-flex align-items-center justify-content-center w-100">
            <i className={"fa-solid fa-circle-notch fa-spin fa-2x " + color}></i>
        </div>
    )
};

export const CoursesHeader = ({ menu }) => {
    return (
        <>
            <div>
                {/* <i className={"fa-solid " + menu.iconName + " fa-2x"}></i> */}
                <span className="h3">{menu.name}</span>
            </div>
            <div className="lineSeparator"></div>
        </>
    )
}

export const SideBar = () => {
    const { usersReducer: { loggedInUser, userPrivileges } } = useSelector((state) => state);

    const [menus, setMenus] = React.useState([
        { id: 1, name: "Dashboard", iconName: "fa-dashboard", active: true, pathname: "/dashboard", priviledge: null },
        { id: 2, name: "Users", iconName: "fa-users", active: false, pathname: "/users", priviledge: "user_management" },
        { id: 3, name: "Courses", iconName: "fa-play-circle", active: false, pathname: "/courses", priviledge: "courses_management" },
        { id: 5, name: "Transactions", iconName: "fa-list", active: false, pathname: "/transactions", priviledge: "courses_management" },
        // { id: 5, name: "Creator Courses", iconName: "fa-video", active: false, pathname: "/instructor-courses", priviledge: "courses_management" },
        { id: 4, name: "Settings", iconName: "fa-screwdriver-wrench", active: false, pathname: "/settings", priviledge: "settings_management" }
    ]);

    const [showProfile, setShowProfile] = React.useState(false);

    const dispatch = useDispatch();

    async function checkingUser() {
        let tmpUser = await getUserData();

        if (tmpUser !== null && tmpUser !== undefined) {
            console.log(tmpUser)

            const response = await networkRequest("get-system-user?userID=" + tmpUser, null, "GET");

            if (response.code === 200) {
                dispatch(getUserPrivileges());
                dispatch(SuccessfulAction("LOGIN_USER_SUCCESS", response.payload, null));
            }

        } else {
            console.log("tmpUser", tmpUser)
        }
    };


    React.useEffect(() => {
        if (loggedInUser === null) {
            checkingUser();
        }
        setActiveMenu();

    }, [loggedInUser, window.location.pathname]);

    function setActiveMenu() {

        const path = window.location.pathname;

        let tmp = menus.slice();
        for (let i = 0; i < tmp.length; i++) {
            tmp[i].active = false;
            if (tmp[i].pathname === path) {
                tmp[i].active = true;
            }
        }
        setMenus(tmp);
    }

    return (
        <aside>
            <div id="sidebar" className="nav-collapse" style={{ backgroundColor: "#BD7F00" }}>

                <ul className="sidebar-menu" id="nav-accordion">

                    <p className="centered cursor d-none" onClick={() => setShowProfile(true)}>
                        <img src="assets/img/ui-sam.jpg" className="img-fluid img-circle" alt="profile-pic"
                            style={{ borderRadius: "50%", overflow: "hidden" }} width="60" />
                    </p>
                    {
                        loggedInUser === null ? <GlobalLoader /> :
                            <h5 onClick={() => setShowProfile(false)} className="centered">{`${loggedInUser.first_name} ${loggedInUser.last_name}`}</h5>
                    }


                    {
                        menus.map((menu, i) => (
                            <li className={"sub-menu " + (menu.active ? "active" : "")} onClick={() => setActiveMenu(menu.id)} key={i}>
                                <Link className="d-flex col" to={menu.pathname} title={menu.name}>
                                    <i className={"fa col-3 text-white " + menu.iconName + " fa-2x"}></i>
                                    <span className="text-white">{menu.name}</span>
                                </Link>
                            </li>
                        ))
                    }


                </ul>

            </div>

            {showProfile && (<Profile close={() => setShowProfile(false)} />)}

        </aside>
    )
};

export const CustomTable = ({ tableHeader, tableBody }) => {
    return (
        <table className="table bg-white rounded table-hover border table-responsive">
            {tableHeader}
            {tableBody}
        </table>
    )
}