/*
  User
  These actions are for the user flow
*/
export const SET_USER_ERROR = 'SET_USER_ERROR'

export const ADD_USER_BEGIN = 'ADD_USER_BEGIN';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const ACTIVATE_USER_BEGIN = 'ACTIVATE_USER_BEGIN';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';

export const DEACTIVATE_USER_BEGIN = 'DEACTIVATE_USER_BEGIN';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';

export const LOGOUT_USER_BEGIN = 'LOGOUT_USER_BEGIN';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const EDIT_USER_BEGIN = 'EDIT_USER_BEGIN';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const GET_MORE_USERS_BEGIN = 'GET_MORE_USERS_BEGIN';
export const GET_MORE_USERS_SUCCESS = 'GET_MORE_USERS_SUCCESS';

export const UPDATE_USER_STATUS_BEGIN = 'UPDATE_USER_STATUS_BEGIN';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';

export const UPDATE_USER_VERIFICATION_STATUS_BEGIN = 'UPDATE_USER_VERIFICATION_STATUS_BEGIN';
export const UPDATE_USER_VERIFICATION_STATUS_SUCCESS = 'UPDATE_USER_VERIFICATION_STATUS_SUCCESS';

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const RESEND_ACTIVATION_BEGIN = 'RESEND_ACTIVATION_BEGIN';
export const RESEND_ACTIVATION_SUCCESS = 'RESEND_ACTIVATION_SUCCESS';

export const RECOVER_USER_BEGIN = 'RECOVER_USER_BEGIN';
export const RECOVER_USER_SUCCESS = 'RECOVER_USER_SUCCESS';

export const COMPLETE_RECOVER_USER_BEGIN = 'COMPLETE_RECOVER_USER_BEGIN';
export const COMPLETE_RECOVER_USER_SUCCESS = 'COMPLETE_RECOVER_USER_SUCCESS';

export const MANUAL_ALLOCATION_BEGIN = 'MANUAL_ALLOCATION_BEGIN';
export const MANUAL_ALLOCATION_SUCCESS = 'MANUAL_ALLOCATION_SUCCESS';

/* Users Privileges */
export const GET_USER_PRIVILEGES_BEGIN = 'GET_USER_PRIVILEGES_BEGIN';
export const GET_USER_PRIVILEGES_SUCCESS = 'GET_USER_PRIVILEGES_SUCCESS';
