/** TRANSACTIONS ACTIONS */

export const SET_TRANSACTIONS_ERROR = 'SET_TRANSACTIONS_ERROR';

export const ACKNOWLEDGE_TRANSACTION_BEGIN = 'ACKNOWLEDGE_TRANSACTION_BEGIN';
export const ACKNOWLEDGE_TRANSACTION_SUCCESS = 'ACKNOWLEDGE_TRANSACTION_SUCCESS';

export const INITIATE_TRANSACTION_BEGIN = 'INITIATE_TRANSACTION_BEGIN';
export const INITIATE_TRANSACTION_SUCCESS = 'INITIATE_TRANSACTION_SUCCESS';

export const CHARGE_REQUEST_BEGIN = 'CHARGE_REQUEST_BEGIN';
export const CHARGE_REQUEST_SUCCESS = 'CHARGE_REQUEST_SUCCESS';

export const GET_TRANSACTION_BEGIN = 'GET_TRANSACTION_BEGIN';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';

export const GET_TRANSACTIONS_BEGIN = 'GET_TRANSACTIONS_BEGIN';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';

export const GET_MORE_TRANSACTIONS_BEGIN = 'GET_MORE_TRANSACTIONS_BEGIN';
export const GET_MORE_TRANSACTIONS_SUCCESS = 'GET_MORE_TRANSACTIONS_SUCCESS';

export const VALIDATE_TRANSACTION_BEGIN = 'VALIDATE_TRANSACTION_BEGIN';
export const VALIDATE_TRANSACTION_SUCCESS = 'VALIDATE_TRANSACTION_SUCCESS';

export const TRANSACTION_STATUS_BEGIN = 'TRANSACTION_STATUS_BEGIN';
export const TRANSACTION_STATUS_SUCCESS = 'TRANSACTION_STATUS_SUCCESS';

