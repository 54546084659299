import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CustomTable, Footer, GlobalLoader, Header, SideBar } from "../components/commonEmbeds";
import { getCourses } from "../middleware/action-creators/coursesActionCreators";
import { APICALLLIMIT, numberWithCommas } from "../middleware/APIs/commonApis";
import { useNavigate } from "react-router-dom";


const CreatorCourses = () => {
    const { coursesReducer: {
        courses, lastCourseCount, coursesWaitingForResponse, coursesResponseMessage,
        pullMoreCoursesLoader
    },
        usersReducer: { loggedInUser },
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    function gettingCourses(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, contentCreatorID: loggedInUser?.ID };
        if (appending) {
            params["start"] = courses.length;
        }
        dispatch(getCourses(params, appending));
    };

    useEffect(() => {
        document.title = 'Instructor | Ekozi'; // Change this to the desired title
        if (loggedInUser === null) {
            return navigate("/login");
        };
        gettingCourses();

    }, [loggedInUser]);

    const THeader = <thead className="text-black">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Price (TZS)</th>
            <th scope="col">Featured</th>
            <th scope="col">Best seller</th>
            <th scope="col">Rate</th>
            <th scope="col">Revenue Generated</th>
            <th scope="col">Revenue For Instructor</th>
        </tr>
    </thead>

    const TBody = <tbody>
        {
            courses && courses.map((item, i) => {
                return (
                    <tr className="cursor" key={item.ID}>
                        <th scope="row">{i + 1}.</th>
                        <td className="">{item.name.en}</td>
                        <td>{numberWithCommas(item.price)}</td>
                        <td className={!item.featured ? "text-secondary" : "text-success"}>{item.featured ? "TRUE" : "FALSE"}</td>
                        <td className={!item.best_seller ? "text-secondary" : "text-success"}>{item.best_seller ? "TRUE" : "FALSE"}</td>
                        <td>{item.rate}</td>
                        <td>TZS {numberWithCommas(item.revenue_generated)}</td>
                        <td>TZS {numberWithCommas(item.revenue_generated_for_content_creator)}</td>

                    </tr>
                )
            })
        }
    </tbody>;

    return (
        <section id="container">
            <Header />
            {/* <SideBar /> */}

            <section className="container-fluid">

                <section className="wrapper wrapperHeight">
                    <div className="text-start">
                        <div className="font28 bold">
                            <span className="fa fa-user"></span>&nbsp;
                            {`${loggedInUser?.first_name} ${loggedInUser?.last_name}`}

                            <div className="font18 color99 ms-4">&nbsp;&nbsp;#Courses - {courses.length}</div>

                        </div>

                        {/* <div className="d-flex flex-row align-items-center justify-content-center">
                            <span className="fa fa-user"></span> &nbsp;&nbsp;
                            <div className="font14 bold"></div>
                        </div> */}
                    </div>
                    <hr />
                    <div className="d-flex flex-row justify-content-between">
                        <div><span>Revenue Generated (TZS)</span><h4 className="font20 bold">{numberWithCommas(loggedInUser?.revenue_generated ?? 0)}</h4></div>
                        <div><span>Amount Settled (TZS)</span><h4 className="font20 bold">{numberWithCommas(loggedInUser?.amount_settled ?? 0)}</h4></div>
                    </div>


                    {
                        coursesWaitingForResponse ? <GlobalLoader /> :
                            courses.length === 0 ? <span className="h6 text-danger">{coursesResponseMessage}</span> :
                                <div className="" style={{ paddingTop: 10 }}>
                                    <CustomTable tableHeader={THeader} tableBody={TBody} />

                                    {lastCourseCount === APICALLLIMIT && (
                                        <div className="text-center">
                                            {pullMoreCoursesLoader ? <GlobalLoader /> :
                                                <span onClick={() => gettingCourses(true)} className="loadMore">Load more</span>}
                                        </div>
                                    )}
                                </div>
                    }
                </section>
            </section>

            <Footer />
        </section>
    )
};

export default CreatorCourses;