/**
 * CATEGORY ACTIONS
 * These actions are for CATEGORIES
*/
export const SET_CATEGORY_ERROR = 'SET_CATEGORY_ERROR'

export const ADD_CATEGORY_BEGIN = 'ADD_CATEGORY_BEGIN';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';

export const EDIT_CATEGORY_BEGIN = 'EDIT_CATEGORY_BEGIN';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';

export const GET_CATEGORY_BEGIN = 'GET_CATEGORY_BEGIN';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';

export const GET_CATEGORIES_BEGIN = 'GET_CATEGORIES_BEGIN';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const GET_MORE_CATEGORIES_BEGIN = 'GET_MORE_CATEGORIES_BEGIN';
export const GET_MORE_CATEGORIES_SUCCESS = 'GET_MORE_CATEGORIES_SUCCESS';

export const DELETE_CATEGORY_BEGIN = 'DELETE_CATEGORY_BEGIN';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';


/**
 * SKILL ACTIONS
 * These actions are for SKILLS
*/
export const SET_SKILL_ERROR = 'SET_SKILL_ERROR'

export const ADD_SKILL_BEGIN = 'ADD_SKILL_BEGIN';
export const ADD_SKILL_SUCCESS = 'ADD_SKILL_SUCCESS';

export const DELETE_SKILL_BEGIN = 'DELETE_SKILL_BEGIN';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';

export const EDIT_SKILL_BEGIN = 'EDIT_SKILL_BEGIN';
export const EDIT_SKILL_SUCCESS = 'EDIT_SKILL_SUCCESS';

export const GET_SKILL_BEGIN = 'GET_SKILL_BEGIN';
export const GET_SKILL_SUCCESS = 'GET_SKILL_SUCCESS';

export const GET_SKILLS_BEGIN = 'GET_SKILLS_BEGIN';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';

export const GET_MORE_SKILLS_BEGIN = 'GET_MORE_SKILLS_BEGIN';
export const GET_MORE_SKILLS_SUCCESS = 'GET_MORE_SKILLS_SUCCESS';
