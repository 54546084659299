import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRanks, deleteRank, setSelectedRank, setRankError } from "../middleware/action-creators/PriviledgesActionCreators";
import { formatDate, ProcessPriviledge } from "../middleware/APIs/commonApis";
import PriviledgeGroupsForm from "./RolesForm";
import { GlobalLoader } from "./commonEmbeds";
import { ToggleShowForm } from "../middleware/action-creators/utilitiesActionCreators";


function Roles() {
    const [action, setAction] = React.useState(null)
    const [updateVal, forceUpdate] = React.useState(0)
    const loggedInUser = useSelector((state) => state.usersReducer.loggedInUser)
    const roles = useSelector((state) => state.PriviledgesReducer.ranks)
    const rolesResponseSuccess = useSelector((state) => state.PriviledgesReducer.ranksResponseSuccess)
    const rolesLoading = useSelector((state) => state.PriviledgesReducer.ranksWaitingForResponse)

    const showForm = useSelector((state) => state.utilitiesReducer.showForm)

    const dispatch = useDispatch()
    let processedRanks = <tr>
        <td colSpan={3}><GlobalLoader /></td>
    </tr>


    React.useEffect(() => {
        dispatch(getRanks())
        return () => {
            dispatch(ToggleShowForm(false))
        }
    }, [dispatch])

    React.useEffect(() => {
        if (!ProcessPriviledge(roles, loggedInUser.role_id, "privileges_management", "list")) {
            window.top.location = "/"
        }
    }, [roles, loggedInUser])


    const changeAction = (action) => {
        setAction(action)
        dispatch(setRankError(""))

        dispatch(ToggleShowForm(true))
    }

    const deleteRole = (index) => {
        if (window.confirm("Are you sure you want to delete?")) {
            let ranks = roles.slice()
            ranks[index].updated_by = loggedInUser.ID
            dispatch(deleteRank(ranks[index]))
        }
    }

    const chooseRank = (index) => {
        let ranks = roles.slice()
        dispatch(setSelectedRank(ranks[index]))
        setAction("edit")

        dispatch(ToggleShowForm(true))
        forceUpdate(updateVal + 1)
    }

    const hideForm = () => {
        dispatch(ToggleShowForm(false))
    }

    if (rolesLoading) {
        processedRanks = <tr><td colSpan={3}>
            <GlobalLoader />
        </td></tr>

    } else if (roles === null && rolesLoading === false) {
        processedRanks = <tr><td colSpan={3}>
            {"No record found"}
        </td></tr>
    } else if (rolesResponseSuccess === false && rolesLoading === false && showForm === false && roles.length === 0) {
        processedRanks = <tr><td colSpan={3}>
            {"An error occured while loading"}
        </td></tr>

    } else {
        processedRanks = roles.map((row, index) => {
            return <tr key={index}>
                <td>{row.rank}</td>
                <td>{formatDate(row.date)}</td>
                <td>

                    {
                        ProcessPriviledge(roles, loggedInUser.role_id, "settings_management", "delete") ?
                            <span
                                className="fa fa-trash text-danger cursor"
                                title={"Delete"}
                                onClick={() => deleteRole(index)}
                            >
                            </span>
                            : null
                    }

                    &nbsp;

                    {
                        ProcessPriviledge(roles, loggedInUser.role_id, "settings_management", "edit") ?
                            <span
                                className="fa fa-edit text-warning cursor"
                                title={"Edit"}
                                onClick={() => chooseRank(index)}
                            >
                            </span>
                            : null
                    }


                </td>
            </tr>
        })

    }

    return <div className="row noMargin">

        <div className="text-start rowBtn align-items-start">
            <div className="col-lg-4 col-md-5 col-sm-6">
                <div className="h2">{"Roles"}</div>

                {
                    ProcessPriviledge(roles, loggedInUser.role_id, "settings_management", "add") ?
                        <span title="Add user role" className="btn editBtn" style={{ width: 120 }}
                            onClick={
                                () => {
                                    dispatch(setSelectedRank(null))
                                    changeAction("add")
                                }
                            }
                        >
                            <span className="fa fa-plus-circle"></span> &nbsp; Add Role
                        </span>

                        : null
                }


                <p></p>

                <table className="table bg-white rounded table-hover border table-responsive">
                    <thead className="text-black">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Last Update</th>
                            <th scope="col">....</th>
                        </tr>
                    </thead>
                    <tbody>
                        {processedRanks}
                    </tbody>
                </table>
            </div>

            {showForm && (
                <div className="col-6">
                    <PriviledgeGroupsForm
                        title={"title"}
                        action={action}
                        hideForm={hideForm}
                    />
                </div>
            )}
        </div>


    </div>
}

export default Roles