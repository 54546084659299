/**
 * CHANNELS ACTIONS
 * These actions are for CHANNELS
*/
export const SET_CHANNEL_ERROR = 'SET_CHANNEL_ERROR';

export const ADD_CHANNEL_BEGIN = 'ADD_CHANNEL_BEGIN';
export const ADD_CHANNEL_SUCCESS = 'ADD_CHANNEL_SUCCESS';

export const ACTIVATE_CHANNEL_BEGIN = 'ACTIVATE_CHANNEL_BEGIN';
export const ACTIVATE_CHANNEL_SUCCESS = 'ACTIVATE_CHANNEL_SUCCESS';

export const DEACTIVATE_CHANNEL_BEGIN = 'DEACTIVATE_CHANNEL_BEGIN';
export const DEACTIVATE_CHANNEL_SUCCESS = 'DEACTIVATE_CHANNEL_SUCCESS';

export const EDIT_CHANNEL_BEGIN = 'EDIT_CHANNEL_BEGIN';
export const EDIT_CHANNEL_SUCCESS = 'EDIT_CHANNEL_SUCCESS';

export const GET_CHANNEL_BEGIN = 'GET_CHANNEL_BEGIN';
export const GET_CHANNEL_SUCCESS = 'GET_CHANNEL_SUCCESS';

export const GET_CHANNELS_BEGIN = 'GET_CHANNELS_BEGIN';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';

export const GET_MORE_CHANNELS_BEGIN = 'GET_MORE_CHANNELS_BEGIN';
export const GET_MORE_CHANNELS_SUCCESS = 'GET_MORE_CHANNELS_SUCCESS';

export const DELETE_CHANNEL_BEGIN = 'DELETE_CHANNEL_BEGIN';
export const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';

export const EDIT_CHANNEL_TARRIFS_BEGIN = 'EDIT_CHANNEL_TARRIFS_BEGIN';
export const EDIT_CHANNEL_TARRIFS_SUCCESS = 'EDIT_CHANNEL_TARRIFS_SUCCESS';

export const GET_CHANNEL_TARRIFS_BEGIN = 'GET_CHANNEL_TARRIFS_BEGIN';
export const GET_CHANNEL_TARRIFS_SUCCESS = 'GET_CHANNEL_TARRIFS_SUCCESS';

export const GET_MORE_CHANNEL_TARRIFS_BEGIN = 'GET_MORE_CHANNEL_TARRIFS_BEGIN';
export const GET_MORE_CHANNEL_TARRIFS_SUCCESS = 'GET_MORE_CHANNEL_TARRIFS_SUCCESS';

