import React from "react";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import Configurations from "../components/configs";
import FaqsComponent from "../components/faqs";
import Roles from "../components/Roles";


const Settings = () => {

    const [menus, setMenu] = React.useState([
        { name: "Configurations", active: true, iconName: "fa-gears" },
        { name: "FAQs", active: false, iconName: "fa-question" },
        { name: "Privileges", active: false, iconName: "fa-unlock-keyhole" }
    ]);

    function toggleMenu(item) {
        let tmp = menus.slice();
        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].name === item.name) {
                tmp[x].active = true
            } else {
                tmp[x].active = false
            }
        }
        setMenu(tmp);
    };


    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <div className="d-flex flex-row">
                        {
                            menus.map((item, i) => (
                                <div key={i} onClick={() => toggleMenu(item)} className={(item.active ? "settingsButtonActive" : "settingsButtonInActive") + " me-3 mt-2"}>
                                    <i className={"fa-solid " + item.iconName + " fa-2x mb-2"}></i>
                                    <span className="">{item.name}</span>
                                </div>
                            ))
                        }
                    </div>

                    {
                        menus[0].active ? <Configurations /> : menus[1].active ? <FaqsComponent /> : <Roles />

                    }

                </section>
            </section>

            <Footer />

        </section>
    )
}

export default Settings;