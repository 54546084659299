/**
 * 
 */
export const LOCATIONS_ERROR = 'LOCATIONS_ERROR'

export const ADD_LOCATION_BEGIN = 'ADD_LOCATION_BEGIN';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';

export const DELETE_LOCATION_BEGIN = 'DELETE_LOCATION_BEGIN';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';

export const EDIT_LOCATION_BEGIN = 'EDIT_LOCATION_BEGIN';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';

export const GET_LOCATION_BEGIN = 'GET_LOCATION_BEGIN';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

export const GET_LOCATIONS_BEGIN = 'GET_LOCATIONS_BEGIN';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
