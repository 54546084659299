import React from "react";
import { Footer, GlobalLoader, Header, SideBar } from "../components/commonEmbeds";
import { networkRequest, numberWithCommas } from "../middleware/APIs/commonApis";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const icons = ["fa-users", "fa-chalkboard-user", "fa-video", "fa-list-ol", "fa-chart-bar"];

const statNames = ["VIDEOS", "COURSES", "END_USER", "SUCCESSFUL_TRANSACTIONS_AMOUNTS", "SUCCESSFUL_TRANSACTIONS_COUNT"]
const Dashboard = () => {

    const { loggedInUser } = useSelector((state) => state.usersReducer)
    const navigate = useNavigate();

    const [networkProps, setNetworkProps] = React.useState({
        isLoading: false, message: null, aggregates: []
    });

    React.useEffect(() => {
        gettingStatistics();

        if (loggedInUser === null) {
            return navigate("/login");
        };
    }, [loggedInUser]);

    async function gettingStatistics() {

        setNetworkProps({
            isLoading: true, message: null, aggregates: []
        });

        const url = "get-aggregates?statName=" + statNames.join(",");

        const response = await networkRequest(url, null, "GET");

        if (response.code === 200) {

            setNetworkProps({
                isLoading: false, message: null, aggregates: response.payload
            });
        } else {
            setNetworkProps({
                isLoading: false, message: response.message, aggregates: []
            });
        }
    };

    function getNumberOfEntry(entryName) {
        for (let i = 0; i < networkProps.aggregates.length; i++) {
            if (networkProps.aggregates[i].entry_name === entryName) {
                return networkProps.aggregates[i].entry_value;
            }
        }
    }

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight py-1">

                    {
                        networkProps.isLoading ? <GlobalLoader /> :
                            networkProps.aggregates.length === 0 ?
                                <div className="text-danger">{networkProps.message}</div>
                                :
                                <div className="d-flex flex-row flex-wrap mt-3 justify-content-center">

                                    <div className={"statsDiv col-5"} >
                                        <i className={"fa-solid fa-4x " + icons[2]}></i>
                                        <p>{"VIDEOS"}</p>
                                        <h3>{numberWithCommas(getNumberOfEntry("VIDEOS"))}</h3>
                                    </div>
                                    <div className={"statsDiv col-5"} >
                                        <i className={"fa-solid fa-4x " + icons[1]}></i>
                                        <p>{"COURSES"}</p>
                                        <h3>{numberWithCommas(getNumberOfEntry("COURSES"))}</h3>
                                    </div>
                                    <div className={"col-5 statsDiv"} >
                                        <i className={"fa-solid fa-4x " + icons[4]}></i>
                                        <p>{"SUCCESSFUL TRANSACTIONS AMOUNTS"}</p>
                                        <h3>{numberWithCommas(getNumberOfEntry("SUCCESSFUL_TRANSACTIONS_AMOUNTS"))}</h3>
                                    </div>
                                    <div className={"col-5 statsDiv"} >
                                        <i className={"fa-solid fa-4x " + icons[3]}></i>
                                        <p>{"NUMBER OF SUCCESSFUL TRANSACTIONS"}</p>
                                        <h3>{numberWithCommas(getNumberOfEntry("SUCCESSFUL_TRANSACTIONS_COUNT"))}</h3>
                                    </div>
                                    <div className={"col-10 statsDiv"} >
                                        <i className={"fa-solid fa-4x " + icons[0]}></i>
                                        <p>{"NUMBER OF USERS"}</p>
                                        <h3>{numberWithCommas(getNumberOfEntry("END_USER"))}</h3>
                                    </div>
                                    {/* {networkProps.aggregates.map((result, index) => (

                                        <div key={index} className={"col text-center py-2 text-white"} style={{ backgroundColor: (index % 2 !== 0) ? "#ffb703" : "#014f86" }}>
                                            <i className={"fa-solid fa-4x " + icons[index]}></i>
                                            <p>{result.entry_name}</p>
                                            <h3>{numberWithCommas(result.entry_value)}</h3>
                                        </div>
                                    )
                                    )} */}
                                </div>
                    }
                </section>

            </section>

            <Footer />

        </section>
    )
}

export default Dashboard;