import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFaq, deleteFaq, editFaqs, getFaqs } from "../middleware/action-creators/utilitiesActionCreators";

import { GlobalLoader } from "./commonEmbeds";

let payload = [{ question: "", answer: "", ordering: "", user_id: 1 }];
const FaqsComponent = () => {

    document.title = "FAQs";

    const {
        utilitiesReducer: { faqsArray, utilitiesWaitingForResponse, utilitiesResponseMessage, utilitiesResponseSuccess, utilitiesSmallLoader },
        // usersReducer: { loggedInUser } 
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [isLoad, setLoad] = React.useState(false);
    const [currentIndex, setIndex] = React.useState(null);

    React.useEffect(() => {
        if (faqsArray.length === 0) {
            dispatch(getFaqs({}));
        }
    }, []);

    React.useEffect(() => {
        if (faqsArray.length > 0) {
            payload = [...faqsArray];
            setLoad(!isLoad);
        }
    }, [faqsArray]);

    function inputChangeArray(index, key, value) {
        payload[index][key] = value;
        setLoad(!isLoad);
    }


    function addingMoreFAQs() {

        payload.push({ question: "", answer: "", user_id: 1 });

        setLoad(!isLoad);
    }


    function addingFaq(index) {
        setIndex(index);
        dispatch(addFaq(payload[index]));
    }

    function editingFaq(index) {
        setIndex(index);
        dispatch(editFaqs(payload[index]));
    }


    async function deleteQuestion(ID) {
        if (window.confirm("Are you sure you want to delete?")) {
            dispatch(deleteFaq({ ID }))
        }
    };



    if (utilitiesWaitingForResponse) return <GlobalLoader />
    return (
        <div>
            <br />
            <div className="" style={{ alignItems: "flex-start" }}>

                {utilitiesWaitingForResponse ? <GlobalLoader /> :
                    <div className="whiteElevation">
                        <div className="text-start h5">Frequently Asked Questions</div>
                        {
                            payload.map((faq, i) => (
                                <div className="" key={i}>
                                    <div className="d-flex flex-row align-items-start justify-content-center text-start" key={i}>
                                        <span>{i + 1}.</span>
                                        &nbsp;&nbsp;
                                        <div className="col-5">
                                            <label>Question</label>
                                            <input type="text" className="form-control" value={faq.question} onChange={(e) => inputChangeArray(i, "question", e.target.value)} />
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-5">
                                            <label>Answer</label>
                                            <textarea aria-multiline type="text" className="form-control" value={faq.answer} onChange={(e) => inputChangeArray(i, "answer", e.target.value)} />
                                        </div>
                                        &nbsp;&nbsp;
                                        {/* <div>
                                            <label>Order</label>
                                            <input type="number" className="form-control" value={faq.ordering} onChange={(e) => inputChangeArray(i, "ordering", Number(e.target.value))} />
                                        </div> */}
                                        &nbsp;&nbsp;
                                        <div onClick={() => faq.ID === undefined ? addingFaq(i) : editingFaq(i)} className="saveBtn col-1" style={{ backgroundColor: "#000000" }}>
                                            {
                                                (utilitiesSmallLoader && currentIndex === i) ? <GlobalLoader color="#FFFFFF" /> :
                                                    <span className="h5 text-white m-0">{faq.ID === undefined ? "Add" : "Edit"}</span>
                                            }

                                        </div>
                                        <div onClick={() => deleteQuestion(faq.ID)} className="saveBtn col-1 ms-1">

                                            <span className="h5 text-white m-0">{"Delete"}</span>

                                        </div>
                                    </div>
                                    <div className="lineSeparator"></div>
                                </div>
                            ))
                        }


                        <div className="text-start mt-3">
                            <i className="fa-solid fa-circle-plus fa-xl primaryColor cursor" onClick={addingMoreFAQs}></i>
                        </div>

                        <div className={utilitiesResponseSuccess ? "text-success" : "text-danger" + " text-left"}>{utilitiesResponseMessage}</div>

                    </div>
                }

            </div>

            <div style={{ height: 100 }} />
        </div >
    )
}

export default FaqsComponent;