import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateUser, deactivateUser, getUsers, SetCurrentUser } from "../middleware/action-creators/usersActionsCreators";
import { ACCOUNT_TYPES, APICALLLIMIT, numberWithCommas } from "../middleware/APIs/commonApis";
import { CoursesHeader, GlobalLoader } from "./commonEmbeds";


const Clients = () => {
    const {
        usersReducer: {
            loggedInUser, currentUser, usersSmallLoader, userResponseMessage, userResponseSuccess, users, userWaitingForResponse,
            lastUsersPullCount, usersLoader
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();


    React.useEffect(() => {
        gettingUsers();
    }, []);

    function gettingUsers(appending = false) {
        let params = { start: 0, count: APICALLLIMIT, accountType: ACCOUNT_TYPES.CLIENT };

        if (appending) {
            params["start"] = users.length;
        }

        dispatch(getUsers(params, appending));

    };

    function edittingUser(status) {

        let payload = currentUser;

        payload["edited_by"] = loggedInUser.ID ?? 1;

        if (status === "active") {
            dispatch(deactivateUser(payload));
        } else {
            dispatch(activateUser(payload));
        }

    }

    return (
        <div className="d-flex flex-row justify-content-between">
            <div className=" col-lg-6 col-md-6 col-sm-6 my-2 p-3 bg-white text-start">
                <CoursesHeader menu={{ name: "Clients" }} />
                {/* <div className="h5">Clients</div> */}
                {

                    userWaitingForResponse ? <GlobalLoader /> :
                        <table className="table bg-white rounded table-hover border table-responsive ">
                            <thead className="text-black">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">First</th>
                                    <th scope="col">Last</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map((item, i) => (
                                        <tr onClick={() => dispatch(SetCurrentUser(item))} key={i}>
                                            <th scope="row">{numberWithCommas(i + 1)}</th>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item?.mobile || "-"}</td>
                                            <td className={"text-uppercase " + (item.account_status.toLowerCase() === "active" ? "text-success" : "text-warning")}>{item.account_status}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            {lastUsersPullCount === APICALLLIMIT && (
                                <div className="text-center">
                                    {usersLoader ? <GlobalLoader /> :
                                        <span onClick={() => gettingUsers(true)} className="loadMore">Load more</span>}
                                </div>
                            )}
                        </table>
                }

                <span className="btn btn-round bg-warning mt-3 centered float-end px-3 d-none">Load more</span>
            </div>


            <div className="col-lg-4 col-md-5 col-sm-6 p-2">

                {
                    currentUser !== null ?

                        <div className="whiteElevation p-3 text-start">

                            <div className="h5">User Details</div>
                            <div className="row">
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" value={currentUser.first_name} readOnly />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" value={currentUser.last_name} readOnly />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Email</label>
                                    <input type="email" className="form-control" value={currentUser.email} readOnly />
                                </div>
                                <div className="col-md-6 mt-md-0 mt-3"> <label>Mobile</label>
                                    <input type="email" className="form-control" value={currentUser?.mobile} readOnly />
                                </div>
                            </div>
                            {!userResponseSuccess && <div className="text-danger mt-2">{userResponseMessage}</div>}

                            <>
                                {
                                    usersSmallLoader ? <div className="mt-3"><GlobalLoader /></div> :
                                        <div onClick={() => edittingUser(currentUser.account_status.toLowerCase())}
                                            className={"cursor text-center rounded mt-4 py-2 text-white " + (currentUser.account_status.toLowerCase() === "active" ? "bg-danger" : "bg-success")} >
                                            <span>{currentUser.account_status.toLowerCase() === "active" ? "DISABLE" : "ACTIVATE"}</span>
                                        </div>}
                            </>

                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default Clients;