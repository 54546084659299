import React, { useEffect } from "react";
import Administrators from "../components/administrators";
import Clients from "../components/clients";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import ContentCreators from "../components/contentCreators";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Users = () => {

    const { loggedInUser } = useSelector((state) => state.usersReducer)
    const navigate = useNavigate();


    useEffect(() => {
        if (loggedInUser === null) {
            return navigate("/login");
        };
    }, [loggedInUser]);

    const [menus, setMenu] = React.useState([
        { name: "Administrators", active: true, iconName: "fa-users-gear" },
        { name: "Clients", active: false, iconName: "fa-users" },
        { name: "Instructors", active: false, iconName: "fa-users-gear" }
    ]);

    function toggleMenu(item) {
        let tmp = menus.slice();
        for (let x = 0; x < tmp.length; x++) {
            if (tmp[x].name === item.name) {
                tmp[x].active = true
            } else {
                tmp[x].active = false
            }
        }
        setMenu(tmp);
    }

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <div className="d-flex flex-row mt-2">
                        {
                            menus.map((item, i) => (
                                <div key={i} onClick={() => toggleMenu(item)} className={(item.active ? "settingsButtonActive" : "settingsButtonInActive") + " me-3"}>
                                    <i className={"fa-solid " + item.iconName + " fa-2x"} style={{ marginBottom: 7 }}></i>
                                    <span className="">{item.name}</span>
                                </div>
                            ))
                        }
                    </div>

                    {
                        menus[0].active ? <Administrators /> : menus[1].active ? <Clients /> : <ContentCreators />

                    }


                </section>

            </section>

            <Footer />

        </section>
    )
}

export default Users;