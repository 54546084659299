import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

let tmpUser = {};
const Profile = ({ close }) => {

    const navigate = useNavigate();

    const { usersReducer: { loggedInUser } } = useSelector((state) => state);

    React.useEffect(() => {
        tmpUser = { ...loggedInUser };
    }, [loggedInUser]);

    const [reload, setReloading] = React.useState(false);

    function inputChange(key, value) {
        tmpUser[key] = value;
        setReloading(!reload);
    };

    if (loggedInUser === null) {
        navigate("/login");
    }

    return (
        <div className="bg-light rounded-2 w-50 d-flex align-items-center flex-column"
            style={{ height: "auto", position: 'absolute', zIndex: 99, right: "25%", top: "25%", paddingTop: 50, paddingBottom: 50 }}>

            <i className="fa-solid fa-circle-xmark fa-3x text-danger"
                onClick={close}
                style={{ position: "absolute", top: 20, right: 20 }}></i>

            <img src={(tmpUser.photo === "" || tmpUser.photo === undefined) ? "assets/img/placeholder.png" : tmpUser.photo}
                alt="profile-picture" className="img-fluid" style={{ width: 200, height: 200 }} />

            <br />
            <div className="d-flex flex-column">
                <label htmlFor="firstName" className="text-start">First name</label>
                <input type="text" id="firstName" value={tmpUser.first_name} onChange={(e) => inputChange("first_name", e)} />
            </div>

            <br />
            <div className="d-flex flex-column">
                <label htmlFor="lastName" className="text-start">Last name</label>
                <input type="text" id="lastName" value={tmpUser.last_name} onChange={(e) => inputChange("last_name", e)} />
            </div>

            <br />
            <div className="d-flex flex-column">
                <label htmlFor="Email" className="text-start">Email</label>
                <input type="text" id="email" value={tmpUser.email} onChange={(e) => inputChange("email", e)} />
            </div>

        </div>
    )
};

export default Profile;