import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { NotFound } from "./pages/404";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import MainCoursesComponent from "./pages/mainCourses";
import SectionPage from "./pages/sectionPage";
import Settings from "./pages/settings";
import Transactions from "./pages/transactions";
import Users from "./pages/users";
import VideoPage from "./pages/videosPage";
import CreatorCourses from "./pages/creatorCourses";
import ContentCreatorLogin from "./pages/contentCreatorLogin";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/courses" element={<MainCoursesComponent />} />
          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/instructor-login" element={<ContentCreatorLogin />} />

          <Route path="/instructor-courses" element={<CreatorCourses />} />
          <Route path="/section/:courseID" element={<SectionPage />} />
          <Route path="/video/:courseID" element={<VideoPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
