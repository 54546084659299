import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { completeRecoverUser, login, recoverUser, resendActivationCode } from "../middleware/action-creators/usersActionsCreators";
import { ACCOUNT_TYPES } from "../middleware/APIs/commonApis";

const ContentCreatorLogin = () => {

    const {
        usersReducer: { userResponseMessage, userWaitingForResponse, userResponseSuccess, loggedInUser, showOtpForm, currentUser
        } } = useSelector((state) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        document.title = 'Instructor Login | Ekozi'; // Change this to the desired title
        if (loggedInUser !== null && loggedInUser.account_type === ACCOUNT_TYPES.ADMIN) {
            navigate("/dashboard");
        }
        if (loggedInUser !== null && loggedInUser.account_type === ACCOUNT_TYPES.CONTENT_CREATOR) {
            navigate("/instructor-courses");
        }
    }, [loggedInUser, navigate]);


    function submit(e) {
        e.preventDefault();
        if (userWaitingForResponse) {
            return
        }

        let form = {},
            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        }

        form["channel"] = "direct";
        form["account_type"] = ACCOUNT_TYPES.CONTENT_CREATOR;

        dispatch(login(form));
    };

    function recoveringUser(e) {
        e.preventDefault();
        if (userWaitingForResponse) {
            return
        }

        let form = {},
            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        }

        form["channel"] = "direct";

        dispatch(recoverUser({ ...form }));
    };

    function completeRecovery(e) {
        e.preventDefault();
        if (userWaitingForResponse) {
            return
        }

        let form = {},
            formData = new FormData(e.target);
        for (let pair of formData.entries()) {
            let key = pair[0],
                value = pair[1];
            form[key] = value;
        }

        form["channel"] = "direct";
        form["otp"] = Number(form.otp);
        form["email"] = currentUser.email;

        dispatch(completeRecoverUser({ ...form }));
    };

    function toggleModal() {
        setShowModal(!showModal);
    };

    function resendOTP() {
        dispatch(resendActivationCode({ email: currentUser.email }));
    };

    return (
        <div id="login-page">
            <div className="container">

                <div className="form-login">
                    <h2 className="loginHeading">{showModal ? "recover password" : "sign in now"}</h2>
                    <>
                        {
                            !showModal ?
                                <form className="login-wrap d-flex flex-column" onSubmit={submit}>

                                    <input type="text" className="form-control" placeholder="Email" name="email" autoFocus required />
                                    <br />
                                    <input type="password" className="form-control" placeholder="Password" name="password" required />
                                    <div className="my-2 cursor" onClick={toggleModal}>
                                        <span className="p-2" >
                                            <span className="link-primary">Forgot Password?</span>
                                        </span>
                                    </div>

                                    <div className={(userResponseSuccess ? "text-success" : "text-danger") + " text-left"}>{userResponseMessage}</div>

                                    {
                                        userWaitingForResponse ?
                                            <div>
                                                <i className="fa-solid fa-circle-notch fa-spin fa-2x primaryColor "></i>
                                            </div> :
                                            <button type="submit" className="btn btn-dark text-white w-100 button">Instructor Login</button>
                                    }
                                    {/* <button className="btn editBtn w-100" type="submit"><i className="fa fa-lock me-2"></i> SIGN IN</button> */}
                                    <hr />

                                    <div className="login-social-link centered d-none">
                                        <p>or you can sign in via your social network</p>
                                        <button className="btn btn-facebook" type="submit"><i className="fa fa-facebook"></i> Facebook</button>
                                        <button className="btn btn-twitter" type="submit"><i className="fa fa-twitter"></i> Twitter</button>
                                    </div>
                                    <div className="registration d-none">
                                        Don't have an account yet?<br />
                                        <a className="" href="/signup">
                                            Create an account
                                        </a>
                                    </div>
                                </form>
                                :
                                showOtpForm ?
                                    <form className="login-wrap d-flex flex-column" onSubmit={completeRecovery}>

                                        <p>Enter the code sent to your e-mail address for ccomplete recovery.</p>
                                        <input type="number" name="otp" placeholder="OPT" autocomplete="off" className="form-control placeholder-no-fix" />
                                        <br />
                                        <input type="password" className="form-control" placeholder="Password" name="password" required />
                                        <br />
                                        <input type="password" className="form-control" placeholder="Confirm Password" name="confirm_password" required />

                                        <div className="my-2 cursor" onClick={resendOTP}>
                                            <span className="p-2" >
                                                <span className="link-primary">Didn't receive? Resend</span>
                                            </span>
                                        </div>

                                        <div className={(userResponseSuccess ? "text-success" : "text-danger") + " text-left"}>{userResponseMessage}</div>

                                        {
                                            userWaitingForResponse ?
                                                <div>
                                                    <i className="fa-solid fa-circle-notch fa-spin fa-2x primaryColor "></i>
                                                </div> :
                                                <button type="submit" className="btn btn-dark text-white w-100 button">Compelete Recover</button>
                                        }

                                        <hr />
                                        <div className="my-2 cursor" onClick={toggleModal}>
                                            <span className="p-2" >
                                                <span className="link-primary primaryColor">Login</span>
                                            </span>
                                        </div>
                                    </form>
                                    :
                                    <form className="login-wrap d-flex flex-column" onSubmit={recoveringUser}>

                                        <p>Enter your e-mail address below to reset your password.</p>
                                        <input type="text" name="email" placeholder="Email" autocomplete="off" className="form-control placeholder-no-fix" />

                                        <br />
                                        <div className={userResponseSuccess ? "text-success" : "text-danger" + " text-left"}>{userResponseMessage}</div>

                                        {
                                            userWaitingForResponse ?
                                                <div>
                                                    <i className="fa-solid fa-circle-notch fa-spin fa-2x primaryColor "></i>
                                                </div> :
                                                <button type="submit" className="btn btn-dark text-white w-100 button">Recover</button>
                                        }

                                        <hr />
                                        <div className="my-2 cursor" onClick={toggleModal}>
                                            <span className="p-2" >
                                                <span className="link-primary primaryColor">Login</span>
                                            </span>
                                        </div>
                                    </form>
                        }

                    </>



                </div>

            </div>
        </div>
    )
}

export default ContentCreatorLogin;