/*
  These actions are for utilities
*/

export const SET_UTILITIES_ERROR = 'SET_UTILITIES_ERROR'

export const ADD_FAQ_BEGIN = 'ADD_FAQ_BEGIN';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';

export const EDIT_FAQ_BEGIN = 'EDIT_FAQ_BEGIN';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';

export const DELETE_FAQ_BEGIN = 'DELETE_FAQ_BEGIN';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';

export const GET_FAQS_BEGIN = 'GET_FAQS_BEGIN';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';

