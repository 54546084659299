import React, { useEffect } from "react";
import { Footer, Header, SideBar } from "../components/commonEmbeds";
import VideosComponent from "../components/videos";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const VideoPage = () => {
    const navigate = useNavigate();
    const { loggedInUser } = useSelector((state) => state.usersReducer)

    useEffect(() => {
        if (loggedInUser === null) {
            return navigate("/login");
        };
    }, [loggedInUser])

    return (
        <section id="container">
            <Header />
            <SideBar />
            <section id="main-content">
                <section className="wrapper wrapperHeight">

                    <VideosComponent />

                </section>
            </section>

            <Footer />

        </section>
    )
}

export default VideoPage;