import React from "react";
import ReactQuill from 'react-quill'; // ES6
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../middleware/action-creators/coursesActionCreators";
import { ASSETS_BASE_URL, breakUpFile, networkRequest } from "../middleware/APIs/commonApis";

import { GlobalLoader } from "./commonEmbeds";

const configs = [
    { id: 1, name: "Privacy Policy", key_name: "privacy_policy" },
    { id: 2, name: "Terms and conditions", key_name: "terms" },
    { id: 3, name: "About Ekozi", key_name: "about" },
    // { id: 7, name: "Frequently Asked Questions", key_name: "faqs" },
    { id: 5, name: "Social Media", key_name: "social_media" },
    // { id: 4, name: "Change password" },
    { id: 6, name: "Image sliders (Ads)", key_name: "home_slider" },
]
const Configurations = () => {

    document.title = "Configuration";

    const { usersReducer: { loggedInUser } } = useSelector((state) => state);

    const { courses, coursesWaitingForResponse } = useSelector((state) => state.coursesReducer);
    const [activeConfig, setActiveConfig] = React.useState("");
    const [networkProps, setNetworkProps] = React.useState({
        loading: false, success: false, message: null
    });
    const [isLoad, setLoad] = React.useState(false);
    const [utilities, setUtilities] = React.useState(null);
    const [passwords, setPasswords] = React.useState({
        password: "",
        confirm_password: ""
    });

    const dispatch = useDispatch();

    function togglingActiveConfig(conf) {
        switch (conf.id) {
            case 4:
            case 7:
                setActiveConfig(conf.id);
                break;
            case 6:
                dispatch(getCourses({}));
                gettingSetting(conf);
                break;
            default:
                gettingSetting(conf);

        }
    };

    async function gettingSetting(conf) {
        setNetworkProps({
            loading: true, success: false, message: null
        });
        setUtilities(null);
        const url = "get-settings?key=" + conf.key_name;
        const response = await networkRequest(url, null, "GET");

        if (response.code === 200) {

            console.log(conf.id)
            console.log(response.payload)

            setActiveConfig(conf.id);
            setUtilities(response.payload);
            setNetworkProps({
                loading: false, success: true, message: null
            });
        } else {
            setNetworkProps({
                loading: false, success: false, message: response.message
            });
        }

    }

    function inputChange(value) {

        setUtilities(prevState => ({
            ...prevState,
            value: {
                ...prevState.value, "description": value
            }

        }));

    }

    function inputChangeArray(index, key, value) {

        let tmp = utilities;

        for (let x = 0; x < tmp.value.length; x++) {
            tmp.value[index][key] = value;
        }

        setUtilities(tmp);

        setLoad(!isLoad);
    }

    function addingMoreRows() {
        let tmp = utilities;

        if (tmp.value === null) {
            tmp["value"] = [];
        }
        tmp.value.push({ name: "Name here", font_icon: "/assets/img/placeholder.png", link: "Link here" });

        setUtilities(tmp);

        setLoad(!isLoad);
    }

    function addingMoreSlider() {
        let tmp = utilities;

        if (tmp.value === null) {
            tmp["value"] = [];
        }

        tmp.value.push({ image: "", screen: "", link: "" });

        setUtilities(tmp);

        setLoad(!isLoad);
    }


    async function updatingSetting() {

        let payload = { ...utilities };

        payload["edited_by"] = loggedInUser.ID;

        setNetworkProps({
            loading: true, success: false, message: null
        });

        const response = await networkRequest("update-settings", payload, "POST");

        if (response.code === 200) {
            setUtilities(response.payload);
            setNetworkProps({
                loading: false, success: true, message: response.message
            });
        } else {
            setNetworkProps({
                loading: false, success: false, message: response.message
            });
        }
    }

    function changingPassword(key, value) {
        setPasswords(prevState => ({
            ...prevState, [key]: value
        }));
    };

    async function changingUserPasswords(e) {
        e.preventDefault();
        // let tmpUser = loggedInUser;

        // setLoading(true);
        // tmpUser["password"] = passwords.password;
        // tmpUser["confirm_password"] = passwords.confirm_password;
        // tmpUser["edited_by"] = loggedInUser.ID;

        // const response = await networkRequest("update-system-user", tmpUser, "POST");

        // if (response.code === 200) {
        //     setResponse({ message: response.message, success: true });
        //     document.getElementById('passwordsForm').reset();
        //     setLoading(false);
        // } else {
        //     setResponse({ message: response.message, success: false });
        //     setLoading(false);
        // }
    };

    async function sendingFile(file, name, index) {
        inputChangeArray(index, "image", file);
    };

    async function deleteSlider(index) {
        if (window.confirm("Are you sure you want to delete?")) {

            setNetworkProps({
                loading: true, success: false, message: null
            });

            let payload = utilities;
            payload["edited_by"] = 1;

            payload.value.splice(index, 1);

            const response = await networkRequest("update-settings", payload, "POST");
            if (response.code === 200) {
                setUtilities(response.payload);
                setNetworkProps({
                    loading: false, success: true, message: response.message
                });
            } else {
                setNetworkProps({
                    loading: false, success: false, message: response.message
                });
            }
        }
    };

    function gettingCourseName(index) {

        for (let x = 0; x < courses.length; x++) {
            if (x === index) {
                return courses[x].name.en;
            }
        }

    }
    return (
        <div>
            <div>
                <br />
                <div className="rowBtn" style={{ alignItems: "flex-start" }}>

                    <div className="col-lg-4 col-md-6 col-sm-8 align-items-start">
                        <div className="flex-column ">
                            <div className="text-start h5">Configuration</div>
                            <div className="lineSeparator"></div>

                            {
                                configs.map((item, index) => (
                                    <div key={index}
                                        onClick={() => togglingActiveConfig(item)}
                                        className={"deepBlueColor text-start " + (item.id === Number(activeConfig) ? "activeConfiguration" : "configuration")}>
                                        <i className="fa-solid fa-angle-right"></i><span className=" margin08">{item.name}</span>
                                    </div>
                                ))
                            }


                        </div>
                    </div>


                    {networkProps.loading ? <GlobalLoader /> :
                        <>
                            {activeConfig === 1 && (
                                <div className="whiteElevation col-lg-5 col-md-6 col-sm-8 hFitContent">
                                    <div className="text-start h5">Privacy Policy</div>

                                    <ReactQuill value={utilities.value.description}
                                        onChange={inputChange} />

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}
                            {activeConfig === 3 && (
                                <div className="whiteElevation col-lg-5 col-md-6 col-sm-8 hFitContent">
                                    <div className="text-start h5">About Ekozi</div>

                                    <ReactQuill value={utilities.value.description}
                                        onChange={inputChange} />

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}
                            {activeConfig === 2 && (
                                <div className="whiteElevation col-lg-5 col-md-7 col-sm-8 ">
                                    <div className="text-start h5">Terms and Conditions</div>
                                    <div className="">
                                        <div style={{ marginRight: 10 }}>

                                            <br />
                                            <ReactQuill value={utilities.value.description}
                                                onChange={inputChange} />

                                        </div>

                                        <div className="d-none">
                                            <label className="text-muted text-start">Swahili Terms</label>
                                            <br />
                                            <ReactQuill value={utilities.value.description}
                                                onChange={inputChange} />

                                        </div>

                                    </div>

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}
                            {activeConfig === 7 && (
                                <div className="whiteElevation col-lg-5 col-md-7 col-sm-8 ">
                                    <div className="text-start h5">Frequently Asked Questions</div>
                                    <div className="text-start">
                                        <div className="mt-md-0 mt-3">
                                            <label>Question 1</label>
                                            <input type="text" className="form-control" onChange={(e) => inputChange("first_name", e.target.value)} />
                                        </div>
                                        <div className="mt-2">
                                            <label>Answer 1</label>
                                            <input type="text" className="form-control" onChange={(e) => inputChange("last_name", e.target.value)} />
                                        </div>
                                        <div className="lineSeparator"></div>
                                    </div>


                                    <div className="text-start mt-3">
                                        <i className="fa-solid fa-circle-plus fa-xl primaryColor cursor" onClick={addingMoreSlider}></i>
                                    </div>

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}
                            {activeConfig === 4 && (
                                <form className="form-group whiteElevation col-lg-5 col-md-6 col-sm-8 hFitContent" id="passwordsForm">
                                    <div className="text-start h5">Change password</div>

                                    <br />


                                    <input
                                        type="password"
                                        value={passwords.password}
                                        onChange={(e) => changingPassword("password", e.target.value)}
                                        placeholder="Enter new password"
                                        className="innerInputs"
                                        autoComplete="new-password"
                                        required
                                    />
                                    <input
                                        type="password"
                                        value={passwords.confirm_password}
                                        onChange={(e) => changingPassword("confirm_password", e.target.value)}
                                        placeholder="Confirm new password"
                                        className="innerInputs"
                                        autoComplete="new-password"
                                        required
                                    />
                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <button onClick={changingUserPasswords} className="btn editBtn col-2 float-end">
                                        <span>Save</span>
                                    </button>
                                </form>
                            )}

                            {activeConfig === 5 && (
                                <div className="whiteElevation col-lg-5 col-md-6 col-sm-8 hFitContent" id="passwordsForm">
                                    <div className="text-start h5">Add/Change Social Media Handles</div>

                                    {
                                        utilities.value !== null && utilities.value.map((media, i) => (
                                            <div key={i}>
                                                <div className="row mt-3">
                                                    <span className="text-start">{i + 1}.</span>
                                                    <div className="col-md-3 mt-md-0">
                                                        <input type="text" className="form-control" value={media.name} onChange={(e) => inputChangeArray(i, "name", e.target.value)} />
                                                    </div>
                                                    <div className="col-md-4 mt-md-0 ">
                                                        <input type="text" className="form-control" value={media.link} onChange={(e) => inputChangeArray(i, "link", e.target.value)} />
                                                    </div>
                                                    <div className="col-md-3 mt-md-0 ">
                                                        <input type="text" className="form-control" value={media.font_icon} onChange={(e) => inputChangeArray(i, "font_icon", e.target.value)} />
                                                    </div>
                                                    <div onClick={() => deleteSlider(i)} className="cursor col-2 text-center pt-2 float-end">
                                                        <span className="fa fa-trash text-danger fa-xl"></span>
                                                    </div>
                                                    {/* <div onClick={() => deleteSlider(i)} className="cursor btn-danger col-2 text-center py-1 float-end">Delete</div> */}
                                                </div>
                                            </div>
                                        ))

                                    }

                                    <div className="text-start mt-3">
                                        <i className="fa-solid fa-circle-plus fa-xl primaryColor cursor" onClick={addingMoreRows}></i>
                                    </div>

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}

                            {activeConfig === 6 && (
                                <div className="whiteElevation col-lg-5 col-md-6 col-sm-8 hFitContent" id="passwordsForm">
                                    <div className="text-start h5">Add/Change Image Sliders</div>

                                    {utilities.value !== null && utilities.value.map((ads, i) => (
                                        <div className="text-start" key={i}>
                                            <div className="d-flex flex-column">
                                                <div className="col-md-6 mt-md-0 my-2">
                                                    <div className="h6">{`# ${i + 1}.`}</div>

                                                    <input type="file" onChange={(e) => breakUpFile(e, sendingFile, i)} className="mb-2" id="img" accept="image/*" />
                                                    <div className="col-md-6 mt-md-0">
                                                        <img src={ads.image.includes("base64") ? ads.image : (ASSETS_BASE_URL + ads.image)} alt="Logo" className="img-thumbnail" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-md-0">
                                                <label>Targeted Course</label>
                                                {
                                                    coursesWaitingForResponse ? <GlobalLoader /> :
                                                        <select id="sub" name="screen" onChange={(e) => inputChangeArray(i, "screen", e.target.value)} required className="mb-2" >
                                                            <option value="" selected hidden>{ads.screen === '' ? "Select course" : gettingCourseName(i)}</option>
                                                            {
                                                                courses.map((course, i) => (
                                                                    <option key={i} value={course.ID}>{course.name.en}</option>
                                                                ))
                                                            }
                                                        </select>
                                                }
                                                {/* <input type="text" className="form-control" value={ads.screen} onChange={(e) => inputChangeArray(i, "screen", e.target.value)} /> */}
                                            </div>

                                            <div onClick={() => deleteSlider(i)} className="cursor btn-danger col-2 text-center py-1 float-end">Delete</div>

                                            <br />
                                            <div className="lineSeparator my-4"></div>
                                        </div>
                                    ))}


                                    <div className="text-start mt-3">
                                        <i className="fa-solid fa-circle-plus fa-xl primaryColor cursor" onClick={addingMoreSlider}></i>
                                    </div>

                                    <div className={networkProps.success ? "text-success" : "text-danger" + " text-left"}>{networkProps.message}</div>
                                    <div onClick={updatingSetting} className="editBtn col-2 float-end">
                                        <span>Save</span>
                                    </div>
                                </div>
                            )}

                        </>
                    }

                </div>

            </div>

            <div style={{ height: 100 }} />
        </div >
    );
};

export default Configurations;